import React from "react";
import PageTitle from "../Components/PageTitle";
import {Box, Button, Grid, MenuItem, TextField, Typography} from "@mui/material";
import axios from "axios";
import CheckIcon from "@mui/icons-material/Check";
import OrganisationRow from "../Components/OrganisationRow";

function OrganisationManagement() {
    const [newOrganisation, setNewOrganisation] = React.useState({name: ""});
    const [organisations, setOrganisations] = React.useState([]);
    const [errors, setErrors] = React.useState([]);
    const [btnDisabled, setBtnDisabled] = React.useState(false);

    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
    };

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_API +'/organisations', config)
            .then(r => {
                if(r.status === 200) {
                    setOrganisations(r.data)
                }
            })
    },[])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewOrganisation({
            ...newOrganisation,
            [name]: value,
        });
    };

    const handleSaveNewOrganisation = () => {
        setBtnDisabled(true)
        setErrors([])
        axios.post(process.env.REACT_APP_BACKEND_API +'/organisations', newOrganisation, config)
            .then(r => {
                if(r.status === 201) {
                      setTimeout(() => {
                         window.location.reload();
                     }, 500)
                }
            }).catch((e) => {
            console.log(e)
            if(e.response.status === 400){
                setErrors(e.response.data.message)
            } else if(e.response.status === 409){
                setErrors([e.response.data.message])
            } else {
                setErrors(["Internal Server Error"])
            }
        }).finally(() => setBtnDisabled(false))

    }

    return (
        <>
            <PageTitle title={`Banken Verwaltung`} />
            <Box display={"flex"} flexWrap={"wrap"} p={1}>
                <Box sx={{width:{xs:"100%", md: "70%"},pr:{md: 2}, borderRight: {xs: "none", md: "solid"}, pb: 5, "& > div.organisation-row:nth-of-type(odd)":{bgcolor: "#0066b338"}}}>
                    <Typography variant={"h6"} pb={2}>Alle Banken</Typography>
                    <Grid container sx={{display:"flex", alignItems:"center",  borderBottom:"solid", mb:2,pb:1, pl:1}}>
                        <Grid item xs={1}>
                            ID
                        </Grid>
                        <Grid item xs={8}>
                            Bank Name
                        </Grid>
                    </Grid>
                  {
                      organisations.length > 0 && organisations.map(organisation =>
                            <OrganisationRow organisation={organisation} key={organisation.id}/>
                        )
                    }
                </Box>
                <Box sx={{width:{xs:"100%", md: "30%"},pl:{md: 2}, "& .MuiTextField-root":{my:2}, "& .MuiInputBase-root":{bgcolor: "white"}}}>
                    <Typography variant={"h6"} pb={2}>Neue Bank anlegen</Typography>
                    <TextField name="name" value={newOrganisation.name} onChange={handleInputChange} fullWidth label="Name der Bank" variant="outlined" required helperText={"Mindestens 10 Zeichen"}/>
                    {errors.length > 0 &&
                        <Box mb={2}>
                            <Typography variant={"body1"} color={"error"} textAlign={"left"}>Es gabe einen oder mehrere Fehler:</Typography>
                            {errors.map(error => <Typography variant={"body2"} color={"error"} textAlign={"left"}>- {error}</Typography>)}
                        </Box>
                    }
                    <Button variant={"contained"} endIcon={<CheckIcon/>} color={"secondary"} sx={{color: "white", float: "right"}}  disabled={btnDisabled} onClick={handleSaveNewOrganisation}>Anlegen</Button>
                </Box>
            </Box>
        </>
    )
}

export default OrganisationManagement;