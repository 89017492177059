import React, {useEffect, useState} from "react";
import {
    Box,
    Button, Checkbox, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider, FormControlLabel, FormGroup,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography,
    Autocomplete
} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddIcon from "@mui/icons-material/Add";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from "axios";
import dayjs from "dayjs";
import {useAuth} from "../AuthContext";

const validateEmail = (email) => {

    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};
function ContractStatus({uuid, status, open, handleClose, newStatus}) {
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [toEmails, setToEmails] = React.useState([]);
    const [toEmailsInput, setToEmailsInput] = React.useState('');
    const [dateValue, setDateValue] = React.useState(dayjs());
    const [openDatePicker, setOpenDatePicker] = React.useState(false);
    const [sendEmailChecked, setSendEmailChecked] = React.useState(false)
    const [emailError, setEmailError] = useState(false)
    const { authenticatedUser } = useAuth()


    const serviceProviders = authenticatedUser.role === "super-admin" ? [
        {label: "Admijalo (vertrieb@admijalo.de)", email: "vertrieb@admijalo.de"},
        {label: "UNITY (michael.happ@unity.de)", email: "michael.happ@unity.de"}
    ] : []

    const setNewStatusForContract = () => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
        };

        const formData = {
            uuid,
            newStatus: {
                title: inputValue,
                date: dateValue.toJSON(),
                toEmails: toEmails.length > 0 ?toEmails.map((emails) => emails.email) : []
            }
        }
        axios.post(process.env.REACT_APP_BACKEND_API + `/contract/update/status`, formData, config)
            .then(res => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000)
            })
            .catch(e => {
                console.log(e)
            })
    }

    useEffect(() => {
        if (!sendEmailChecked){
            setToEmails([])
        }
    }, [sendEmailChecked]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Aktueller Status der Dienstleistung "}
            </DialogTitle>
            <DialogContent>
                <List sx={{
                    "& > li":{py:0},
                    "& > div:first-child #verticalLine":{top: "50%"},
                    "& > div:last-child #verticalLine":{bottom: "50%"},
                    "& > div:last-child hr":{display: "none"},
                }}>
                    { status ?
                        status
                            .sort((a, b) => new Date(b.date) - new Date(a.date))
                            .map((status, index) =>
                            <Box key={index} position={"relative"}>
                            <Box sx={{borderLeft: "2px solid green", position: "absolute", top: 0, bottom: 0, left: 27}} id={"verticalLine"}/>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckCircleOutlineIcon color={"success"} sx={{backgroundColor: "#fff"}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={status.title} secondary={new Date(status.date).toLocaleDateString("de-DE", {dateStyle: "full"})} />
                                </ListItem>
                                <Divider />
                            </Box>) :
                        <i>Bislang kein Status gesetzt</i>
                    }
                </List>
                <Typography variant={"h6"} py={2}>Neuen Status setzen</Typography>
                <Autocomplete
                    sx={{mb:2}}
                    id="status-new"
                    freeSolo
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setValue(newInputValue);
                        setInputValue(newInputValue);
                    }}
                    options={newStatus}
                    renderInput={(params) =>
                        <TextField helperText="Maximal 75 Zeichen" {...params} inputProps={{ ...params.inputProps, maxLength: 75}} label="Status wählen oder Eigenen eingeben" />
                }
                />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onAccept={()=>setOpenDatePicker(false)}
                            label="Datum wählen"
                            inputFormat={"DD.MM.YYYY"}
                            minDate={"01.01.2022"}
                            value={dateValue}
                            open={openDatePicker}
                            onChange={(newValue) => {
                                setDateValue(newValue);
                            }}
                            disableFuture
                            renderInput={(params) => <TextField
                                required
                                onClick={() => setOpenDatePicker(true)}
                                helperText="Rechts auf Icon klicken um Datum zu wählen"
                                fullWidth
                                {...params}
                                inputProps={{ ...params.inputProps, placeholder:"Tag.Monat.Jahr"}}
                            />}

                        />
                    </LocalizationProvider>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={sendEmailChecked} onClick={() => setSendEmailChecked(!sendEmailChecked)} />} label="Über aktualisierten Status informieren? (E-Mail)" />
                </FormGroup>
                {sendEmailChecked && <Autocomplete
                    sx={{mt: 1}}
                    id="mail-select"
                    freeSolo
                    multiple
                    value={toEmails}
                    onChange={(event, newValue) => {
                        setToEmails([...newValue].map(val => (typeof val === 'string' || val instanceof String) ? {
                            label: val,
                            email: val
                        } : val));
                    }
                    }
                    inputValue={toEmailsInput}
                    onInputChange={(event, newInputValue) => {
                        setToEmailsInput(newInputValue);
                    }}
                    options={serviceProviders.map(providers => providers)}
                    renderInput={(params) =>
                        <TextField autoComplete="off" {...params}
                                   inputProps={{...params.inputProps, maxLength: 75}}
                                   label="Wählen und/oder E-Mail eingeben" error={emailError} helperText={emailError && "E-Mail Adresse korigieren"}/>
                    }
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip variant="outlined" label={option.label} {...getTagProps({index})} />
                        ))
                    }
                />}
                <Button disabled={dateValue == null || value == null} variant={"contained"} color={"secondary"} sx={{color: "white", mt:2, float: "right"}} endIcon={<AddIcon/>} onClick={setNewStatusForContract}>Neuen Status setzen {toEmails.length > 0 && "& E-Mails senden"}</Button>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    handleClose();
                    setOpenDatePicker(false);
                }}>Schließen</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ContractStatus;