import React from 'react';
import {Box, Grid, TextField} from "@mui/material";

function ServiceProviderContractRowHeader({setTextFilter}) {
    const [searchTerm, setSearchTerm] = React.useState("");
    const handleChange = (e) => {
        setSearchTerm(e.target.value)
        setTextFilter(e.target.value.toLowerCase())
    }
    return (
        <Box>
            <Box sx={{px:2, display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                <TextField value={searchTerm} onChange={handleChange} helperText={"Nach Titel oder Kundenname suchen."} label="Suchen..." autoComplete={"false"} size="small" variant="outlined" sx={{width:"50%", "& > div":{bgcolor: "white"}, "& .MuiFormHelperText-root  p":{m:0}}}/>
            </Box>
            <Grid container sx={{display:"flex", alignItems:"center", px:3, pt:3, pb:1, mb:2, borderBottom:"solid"}} >
                <Grid item xs={3}>
                    Titel
                </Grid>
                <Grid item xs={3}>
                    Unternehmen
                </Grid>
                <Grid item xs={3}>
                    Ansprechpartner zur Durchführung
                </Grid>
                <Grid item xs={3}>
                    Aktionen
                </Grid>
            </Grid>
        </Box>

    )
}

export default ServiceProviderContractRowHeader;