import React from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";

let AuthContext = React.createContext();

export function AuthProvider({ children }) {
    const [authenticatedUser, setAuthenticatedUser] = React.useState({});

    React.useEffect(() => {
        try{
            setAuthenticatedUser(jwtDecode(localStorage.getItem("accessToken")))
        }catch (e) {
            console.log(e.message)
        }
    },[])

    let login =  (username, password) => {
        return new Promise(((resolve, reject) => {
            const data = {username, password}
            axios.post(process.env.REACT_APP_BACKEND_API + "/auth/login", data).then((res) => {
                resolve()
            }).catch((err) => {
                reject(err)
            })
            // if((userName === "rene.hippen" || userName === "max.mustermann") && password === "vbinnovation"){
            //     localStorage.setItem("loggedIn", "true")
            //     resolve()
            // }else {
            //     reject()
            // }
        }))
    };

    let verifyToken = (token, username) => {
        return new Promise(((resolve, reject) => {
            const data = {token, username}
            axios.post(process.env.REACT_APP_BACKEND_API + "/auth/verify-token", data).then((res) => {
                if(res.data === false) reject()
                try{
                    setAuthenticatedUser(jwtDecode(res.data.accessToken))
                }catch (e) {
                    console.log(e.message)
                }finally {
                    resolve(res.data)
                }

            }).catch((err) => {
                reject()
            })
        }))
    }

    let signout = (callback) => {
        localStorage.removeItem("accessToken")
        callback()
    };

    let checkAuth = () => {
        return new Promise(((resolve, reject) => {
            const accessToken = localStorage.getItem("accessToken");
            if(accessToken != null){
                const data = {accessToken}
                axios.post(process.env.REACT_APP_BACKEND_API + '/auth/check-auth', data).then(res => {
                    resolve();
                }).catch(() => {
                    localStorage.removeItem("accessToken");
                    reject();
                })
            }else {
                reject();
            }
        }))
    };

    let value = { login, verifyToken,  signout, checkAuth, authenticatedUser, setAuthenticatedUser };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return React.useContext(AuthContext);
}
