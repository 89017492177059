import React from "react";
import {Box, FormLabel, TextField} from "@mui/material";
import {useNewContract} from "../../NewContractFormProvider";

function Summary() {
    const {template, customer, notes, title, templates, fullCustomer, setNextBtnActive} = useNewContract()

    const getTemplateName = (template) => {
        return templates.find(data => data.id === template).name;
    }

    React.useEffect(() => {
        setTimeout(() => setNextBtnActive(true), 3000)
    },[])

    return (
        <Box sx={{"& > div": {mb:4}}}>
            <FormLabel sx={{mb:3, display:"block"}}>Zusammenfassung. Bitte überprüfen Sie ihre Eingaben.</FormLabel>
            <TextField required label="Titel" fullWidth InputProps={{readOnly: true}} variant="standard" value={title} />
            <TextField required label="Kunde" fullWidth InputProps={{readOnly: true}} variant="standard" value={`${fullCustomer.customerId}: ${fullCustomer.companyName} - ${fullCustomer.companyStreet}, ${fullCustomer.companyPlz} ${fullCustomer.companyPlace}`} />
            <TextField required label="Vertrag" fullWidth InputProps={{readOnly: true}} variant="standard" value={getTemplateName(template)} />
            <TextField label="Notizen" fullWidth InputProps={{readOnly: true}} variant="standard" value={notes ? notes : "-"} />
        </Box>
    )
}

export default Summary;