import React, {useEffect} from "react";
import PageTitle from "../Components/PageTitle";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {Box, Button, MenuItem, TextField, Typography} from "@mui/material";
import {salutations} from "../SelectOptions";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import {defaultValues, getOptionLabelText, sectors} from "../Components/CustomerHelper";
import { Autocomplete } from '@mui/material'
import {useAuth} from "../AuthContext";
import {addZeroToNumbers} from "../Components/CustomersRow";

function EditCustomer() {
    const { authenticatedUser } = useAuth()
    const params = useParams();
    const [formValues, setFormValues] = React.useState(defaultValues);
    const [organisations, setOrganisations] = React.useState([]);
    const [changedFormValues, setChangedFormValues] = React.useState({});
    const [disableSaveBtn, setDisableSaveBtn] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    let navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: value,
        });

        setChangedFormValues({
            ...changedFormValues,
            [name]: value,
        });

        setDisableSaveBtn(false)
    };

    useEffect(() => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
        };
        axios.get(process.env.REACT_APP_BACKEND_API +'/customers/' + params.id, config)
            .then(r => {
                if(r.status === 200) {
                    if(authenticatedUser.role === "super-admin")  r.data.organisationId = r.data.organisation?.id
                    setFormValues(r.data)
                    setIsLoading(false)
                }
            })

        if(authenticatedUser.role === "super-admin"){
            axios.get(process.env.REACT_APP_BACKEND_API +'/organisations', config)
                .then(r => {
                    if(r.status === 200) {
                        setOrganisations(r.data)
                    }
                })
        }
    },[authenticatedUser.role, params.id])

    const handleSaveEdit = (event) => {
        event.preventDefault();
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
        };
        axios.patch(process.env.REACT_APP_BACKEND_API +"/customers/"+ params.id, changedFormValues, config)
            .then(r => {
                navigate(`/customer/${params.id}`);
            })
    }

    const handleCancel= () => {
        navigate(`/customer/${params.id}`);
    }

    return (
        <form>
            <PageTitle title={"Kunde bearbeiten"} />
            {isLoading ? <h3> Lädt Daten...</h3> : <Box display={"flex"} flexWrap={"wrap"} sx={{"& > div": {my: 2, px: 2}, "& .MuiTextField-root": {my: 2, bgcolor: "white"}}}>
                <Box sx={{width: {xs: "100%", md: "50%"}}}>
                    <Typography variant={"h6"}>Unternehmen</Typography>
                    <TextField name="customerId" value={addZeroToNumbers(formValues.id)} onChange={handleInputChange} fullWidth label="Kundennummer" variant="outlined" disabled/>
                    <TextField name="companyName" value={formValues.companyName} onChange={handleInputChange} fullWidth label="Name" variant="outlined" required/>
                    <TextField name="companyStreet" value={formValues.companyStreet} onChange={handleInputChange} fullWidth label="Straße" variant="outlined" required/>
                    <Box>
                        <TextField sx={{width: "50%"}} name="companyPlz" value={formValues.companyPlz} onChange={handleInputChange} fullWidth label="PLZ" variant="outlined" required/>
                        <TextField sx={{width: "50%"}} name="companyPlace" value={formValues.companyPlace} onChange={handleInputChange} fullWidth label="Ort" variant="outlined" required/>
                    </Box>
                    <TextField name="companyEmail" value={formValues.companyEmail} onChange={handleInputChange} fullWidth label="E-Mail" variant="outlined" required/>
                    <TextField name="companyTelephone" value={formValues.companyTelephone} onChange={handleInputChange} fullWidth label="Telefonnummer" variant="outlined" required/>
                    <Box>
                        {/*<TextField sx={{width:"75%", pr:1}} name="sector" value={formValues.sector} onChange={handleInputChange} fullWidth label="Branche" variant="outlined" required />*/}
                        <Autocomplete
                            id={"sectors"}
                            name={"sector"}
                            value={formValues.sector}
                            onChange={(e, value) => handleInputChange({target:{name: "sector", value: value.id}})}
                            isOptionEqualToValue={(option, value) => option.id === value}
                            options={sectors}
                            groupBy={(option) => option.group}
                            getOptionLabel={(option) => getOptionLabelText(option)}
                            sx={{width: "75%", display: "inline-flex"}}
                            renderInput={(params) => <TextField {...params} label="Branche" required/>}
                        />
                        <TextField type={"number"} sx={{width: "25%"}} name="employees" value={formValues.employees} onChange={handleInputChange} fullWidth label="Mitarbeiteranzahl" variant="outlined" required/>
                    </Box>
                </Box>
                <Box sx={{width: {xs: "100%", md: "50%"}}}>
                    <Typography variant={"h6"}>Ansprechpartner für Durchführung </Typography>
                    <TextField name="salutation" value={formValues.salutation} onChange={handleInputChange} fullWidth label="Anrede" select variant="outlined" required>
                        {salutations.map((option) => (
                            <MenuItem key={option.label} value={option.label}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField name="title" value={formValues.title} onChange={handleInputChange} fullWidth label="Title" variant="outlined"/>
                    <TextField name="firstName" value={formValues.firstName} onChange={handleInputChange} fullWidth label="Vorname" variant="outlined" required/>
                    <TextField name="lastName" value={formValues.lastName} onChange={handleInputChange} fullWidth label="Nachname" variant="outlined" required/>
                    <TextField name="position" value={formValues.position} onChange={handleInputChange} fullWidth label="Position" variant="outlined"/>
                    <TextField name="email" value={formValues.email} onChange={handleInputChange} fullWidth label="E-Mail" variant="outlined" required/>
                    <TextField name="telephone" value={formValues.telephone} onChange={handleInputChange} fullWidth label="Telefonnummer" variant="outlined" required/>
                </Box>
                <Box width="100%">
                    <TextField name="notes" value={formValues.notes} onChange={handleInputChange} label="Besonderheiten im Unternehmen" multiline rows={5} fullWidth/>
                    {authenticatedUser.role === "super-admin" && <div>
                        <hr/>
                        <Typography variant={"body1"}>Diese Bereich ist nur für Admins sichtbar</Typography>
                        <TextField name="organisationId" value={formValues.organisationId} onChange={handleInputChange} fullWidth label="Bank" select variant="outlined" required>
                            {organisations.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>}
                </Box>
                <Box width="100%" sx={{p: "0 !important", display: "flex", justifyContent: "flex-end", "& > button": {mx: 2}}}>
                    <Button variant={"contained"} endIcon={<CloseIcon/>} color={"grey"} onClick={handleCancel}>Abbrechen</Button>
                    <Button variant={"contained"} endIcon={<CheckIcon/>} color={"secondary"} sx={{color: "white"}} type="submit" onClick={handleSaveEdit} disabled={disableSaveBtn}>Speichern</Button>
                </Box>
            </Box>}
        </form>

    )
}

export default EditCustomer;