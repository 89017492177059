import React from "react";
import {Box, Link, Typography} from "@mui/material";

function AppFooter() {
    return (
        <Box
            bgcolor={"primary.main"}
            zIndex={1}
            height={75}
            position={"fixed"}
            bottom={0}
            left={0}
            right={0}
            display={"flex"}
            justifyContent={"space-evenly"}
            px={"25%"}
            alignItems={"center"}
            sx={{"& > a":{color: "white"}}}
        >
           {/* <Link href="#" underline="hover">
                Kontakt
            </Link>
            <Link href="#" underline="hover">
                Hilfe
            </Link>
            <Link href="#" underline="hover">
                Impressum
            </Link>*/}
        </Box>
    )
}

export default AppFooter;