import React, {useEffect} from "react";
import PageTitle from "../Components/PageTitle";
import {Box, Button, Divider, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import CustomerContractCard from "../Components/CustomerContractCard";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteDialogCustomer from "../Components/DeleteDialogCustomer";
import ReplayIcon from '@mui/icons-material/Replay';
import {sectors} from "../Components/CustomerHelper";
import {useAuth} from "../AuthContext";

export function LabelText({label, text}) {
    return(
            <Box p={2}>
                <Typography variant={"body1"} sx={{width:"35%", display:"inline-block", color:"#5b5b5b", fontWeight: "bold"}}>{label}</Typography>
                <Typography variant={"body1"} sx={{width:"65%", display:"inline-block"}}>{text}</Typography>
            </Box>
        )
}

const getStatusTextAndColor = (contract) => {
    switch (contract.status) {
        case "IN-PROGRESS":
            contract.statusColor = "#14a1c9"
            contract.statusText = "Wird generiert"
            contract.statusCode = 0
            break;
        case "DRAFT":
            contract.statusColor = "#FFC000"
            contract.statusText = "Im Entwurf"
            contract.statusCode = 1
            break;
        case "SIGNED":
            contract.statusColor = "#70AD47"
            contract.statusText = "Unterschrieben"
            contract.statusCode = 2
            break;
        case "IN-OFFER-PHASE":
            contract.statusColor = "#c97514"
            contract.statusText = "In Angebotsphase"
            contract.statusCode = 4
            break;
        default:
            break;

    }
    return contract
}

function Customer() {
    const [customer, setCustomer] = React.useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [contracts, setContracts] = React.useState([]);
    const [showUpdateBtn, setShowUpdateBtn] = React.useState(false);
    const [updateCountDown, setUpdateCountDown] = React.useState(10);
    const [isLoading, setIsLoading] = React.useState(true);
    const [newStatus, setNewStatus] = React.useState([])

    const params = useParams();
    let navigate = useNavigate();
    const { authenticatedUser } = useAuth()

    useEffect(() => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
        };
        axios.get(process.env.REACT_APP_BACKEND_API +'/customers/' + params.id, config)
            .then(r => {
                if(r.status === 200) {
                    setCustomer(r.data)
                    setIsLoading(false)
                }

            })
        axios.get(process.env.REACT_APP_BACKEND_API +'/contract/customer/' + params.id, config)
            .then(r => {
                if(r.status === 200) {
                    const updateContracts = [];
                    for (const contract of r.data){
                        if(contract.status === "IN-PROGRESS") setShowUpdateBtn(true)
                        updateContracts.push(getStatusTextAndColor(contract))
                    }
                    setContracts(updateContracts)
                }

            })

        axios.get(process.env.REACT_APP_BACKEND_API + `/contract/status`, config)
            .then(res => {
                setNewStatus(res.data)
            })
            .catch(e => {
                console.log(e)
            })
    },[params.id])

    React.useEffect(() => {
        if(showUpdateBtn){
            const id = setInterval(() => {
                setUpdateCountDown(prevState => prevState - 1)
            }, 1000)
            return () => clearInterval(id);
        }
    },[showUpdateBtn])

    React.useEffect(() => {
        if(updateCountDown === 0){
            reload()
        }
    },[updateCountDown])

    const handleEditClick = () => {
        navigate(`/customer/edit/${params.id}`)
    }

    const handleNewContractClick = () => {
        sessionStorage.setItem("customer", JSON.stringify(customer))
        navigate(`/customer/new-contract`)
    }

    const reload = () => {
        window.location.reload()
    }
    
    const getContactPerson = () => {
        let contactPerson;
        contactPerson = customer.title + " " + customer.firstName + " " + customer.lastName;
        if(customer.position)
            contactPerson = contactPerson + " (" + customer.position + ")"
        return contactPerson;
    }

    const getSector = () => {
      if(customer.sector !== "") {
          const sector = sectors.find(sector => sector.id === customer.sector)
          return sector.group + ": " + sector.title
      }
    }

    return (
        <>
    { isLoading ? <h3> Lädt Daten...</h3> :
                <>
        <PageTitle title={`Kunde - ${customer.companyName}`}/>
        <Box display={"flex"} flexWrap={"wrap"} p={1}>
            <Box sx={{width: {xs: "100%", md: "50%"}, pr: {md: 2}, borderRight: {xs: "none", md: "solid"}, pb: 5}}>
                <Typography variant={"h6"} pb={2}>Übersicht</Typography>
                <Box display={"flex"} justifyContent={"flex-end"} pr={2}>
                    <Button variant={"contained"} color={"secondary"} sx={{color: "white", mr: 2}} endIcon={<SettingsIcon/>} onClick={handleEditClick}>Bearbeiten</Button>
                    {contracts.filter(contract => contract.status === "SIGNED").length === 0 &&
                        <Button variant={"contained"} color={"error"} sx={{color: "white"}} endIcon={<DeleteForeverIcon/>} onClick={() => setDeleteDialogOpen(true)}>Löschen</Button>}
                    <DeleteDialogCustomer open={deleteDialogOpen} handleClose={() => setDeleteDialogOpen(false)} customerId={params.id}/>
                </Box>
                <Box>
                    <Typography variant={"body1"} pt={2} sx={{fontWeight: "bold"}}>Allgemeines</Typography>
                    {/*<LabelText label={"Kundennummer"} text={customer.customerId}/>*/}
                    {(customer.organisation && authenticatedUser.role === "super-admin") && <LabelText label={"Bank"} text={customer.organisation.name}/>}
                    <LabelText label={"Straße"} text={customer.companyStreet}/>
                    <LabelText label={"PLZ, Ort"} text={customer.companyPlz + " " + customer.companyPlace}/>
                    <LabelText label={"Telefonnummer"} text={customer.companyTelephone}/>
                    <LabelText label={"E-Mail"} text={customer.companyEmail}/>
                    <LabelText label={"Branche"} text={getSector()}/>
                    <LabelText label={"Mitarbeiteranzahl"} text={customer.employees}/>
                    <Divider/>
                    <Typography variant={"body1"} pt={2} sx={{fontWeight: "bold"}}>Ansprechpartner für Durchführung</Typography>
                    <LabelText label={"Name"} text={getContactPerson()}/>
                    <LabelText label={"Telefonnummer"} text={customer.telephone}/>
                    <LabelText label={"E-Mail"} text={customer.email}/>
                    <Divider/>
                    <Typography variant={"body1"} pt={2} sx={{fontWeight: "bold"}}>Weiteres</Typography>
                    <LabelText label={"Besonderheiten im Unternehmen"} text={customer.notes}/>
                </Box>
            </Box>
            <Box sx={{width: {xs: "100%", md: "50%"}, pl: {md: 2}}}>
                <Typography variant={"h6"} pb={2}>Verträge</Typography>
                <Box display={"flex"} justifyContent={"flex-end"} pr={2}>
                    {showUpdateBtn && <Button variant={"contained"} color={"primary"} sx={{color: "white", mr: 2}} endIcon={<ReplayIcon/>}>Aktualisieren in {updateCountDown}</Button>}
                    <Button variant={"contained"} color={"secondary"} sx={{color: "white"}} endIcon={<AddIcon/>} onClick={handleNewContractClick}>Neuer Vertrag</Button>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"} p={1}>
                    {contracts.length > 0 && contracts.map((contract) => <CustomerContractCard key={contract.id} contract={contract} customerId={customer.id} newStatus={newStatus}/>)}
                    {contracts.length === 0 && <Typography variant={"body1"}>Noch keine Verträge vorhanden</Typography>}
                </Box>
            </Box>
        </Box>
    </>
}
        </>
    )
}

export default Customer;