import React from "react";
import {Box, Button, Container, IconButton, TextField, Typography} from "@mui/material";
import AppHeader from "../Components/AppHeader";
import AppFooter from "../Components/AppFooter";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function PasswordReset() {
    const params = useParams();
    let navigate = useNavigate();
    const [newPassword, setNewPassword] = React.useState("");
    const [newPasswordRepeat, setNewPasswordRepeat] = React.useState("");
    const [btnDisabled, setBtnDisabled] = React.useState(true);
    const [showError, setShowError] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    const handlePasswordChange = (e) => {
        if(e.target.id === "password") {
            setNewPassword(e.target.value)
            if(e.target.value === newPasswordRepeat && e.target.value.length >= 12 ){
                setBtnDisabled(false)
            }else {
                setBtnDisabled(true)
            }
        }
        if(e.target.id === "passwordRepeat") {
            setNewPasswordRepeat(e.target.value)
            if(e.target.value === newPassword && e.target.value.length >= 12){
                setBtnDisabled(false)
            }else {
                setBtnDisabled(true)
            }
        }
    }

    const handleResetPassword = () => {
        setBtnDisabled(true)
            axios.post(process.env.REACT_APP_BACKEND_API + "/auth/reset-password", {uuid: params.uuid, password: newPassword})
                .then((res) => {
                    navigate(`/login`);
                })
                .catch((e) => {setShowError(true)})
    }

    return (
        <Box height={"100vh"}>
            <AppHeader/>
            <Container maxWidth={"xl"} sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", height:"calc(100% - 200px)"}}>
                <Box width={"50%"} display={"flex"} flexDirection={"column"} sx={{"& > *": {my:"8px !important"}}}>
                    <Typography variant={"h4"} textAlign={"center"}>Password zurücksetzen</Typography>
                    <Typography variant={"body1"} textAlign={"center"}>Geben Sie bitte hier neues Passwort ein und wiederholen Sie es.</Typography>
                    <Typography variant={"body1"} textAlign={"center"}>Passwortlänge: Mindestens 12 Zeichen</Typography>
                    <Box position={"relative"}>
                        <TextField id="password" type={showPassword ? "text": "password"} value={newPassword} onChange={handlePasswordChange} sx={{bgcolor:"white"}} fullWidth label="Neues Passwort" variant="outlined" required />
                        <IconButton onClick={() => setShowPassword(!showPassword)} sx={{position:"absolute", right: 5, top: 8}}>{!showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}</IconButton>
                    </Box>
                    <Box position={"relative"}>
                        <TextField id="passwordRepeat"  type={showPassword ? "text": "password"} value={newPasswordRepeat} onChange={handlePasswordChange} sx={{bgcolor:"white"}} fullWidth label="Neues Passwort wiederholen" variant="outlined" required />
                        <IconButton onClick={() => setShowPassword(!showPassword)} sx={{position:"absolute", right: 5, top: 8}}>{!showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}</IconButton>
                    </Box>
                    {showError && <Typography variant={"body1"} color={"error"} textAlign={"center"}>Es gab einen Fehler, bitte versuchen Sie es erneut!</Typography>}
                    <Button sx={{color:"white"}} variant={"contained"} color={"secondary"} disabled={btnDisabled} onClick={handleResetPassword}>Passwort zurücksetzen</Button>
                </Box>
            </Container>
            <AppFooter/>
        </Box>
    )
}

export default PasswordReset