import React, {useEffect, useState} from 'react';
import PageTitle from "../Components/PageTitle";
import {Box} from "@mui/material";
import CustomersRow from "../Components/CustomersRow";
import ServiceProviderContractRowHeader from "../Components/ServiceProviderContractRowHeader";
import axios from "axios";
import ServiceProviderContractRow from "../Components/ServiceProviderContractRow";

function ServiceProviderContracts() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [contracts, setContracts] = useState([]);
    const [textFilter, setTextFilter] = useState("");
    const [newStatus, setNewStatus] = React.useState([])

    React.useEffect(() => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
        };
        axios.get(process.env.REACT_APP_BACKEND_API +"/contract/signed", config)
            .then(r => {
                setContracts(r.data)
                setIsLoading(false)
            })
        axios.get(process.env.REACT_APP_BACKEND_API + `/contract/status`, config)
            .then(res => {
                setNewStatus(res.data)
            })
            .catch(e => {
                console.log(e)
            })
    }, []);


    const filteredContracts= contracts.filter((contract) => {
        if (textFilter.length === 0) return true
        return (contract.contracts_title.toLowerCase().includes(textFilter) || contract.customers_company_name.toLowerCase().includes(textFilter))
    })

    return(
        <>
            <PageTitle title={"Vertragsübersicht für Dienstleister"} />
            <Box sx={{"& > div.contract-row:nth-of-type(odd)":{bgcolor: "#0066b338"}}}>
                {isLoading ? <h3> Lädt Daten...</h3> : (
                    <>
                        <ServiceProviderContractRowHeader setTextFilter={setTextFilter}/>
                        {filteredContracts.length > 0 ?
                            filteredContracts.map((contract) => <ServiceProviderContractRow contract={contract} newStatus={newStatus}/>):
                            <div>{textFilter.length > 0 ? "Kein Kunde mit Suchbegriff gefunden!" : "Keine Kunden vorhanden"}</div>
                        }
                    </>
                )}
            </Box>
        </>

    )
}

export default ServiceProviderContracts;