import {Box, Button, Container, TextField, Typography} from "@mui/material";
import AppHeader from "../Components/AppHeader";
import AppFooter from "../Components/AppFooter";
import React from "react";
import {useAuth} from "../AuthContext";
import {useNavigate, useSearchParams} from "react-router-dom";

function VerifyToken() {
    const [token, setToken] = React.useState("");
    const [showError, setShowError] = React.useState(false);
    const [username, setUsername] = React.useState("");
    let navigate = useNavigate();
    let [searchParams] = useSearchParams();

    React.useEffect(() => {
        setUsername(searchParams.get("user"));
    },[searchParams])

    const {verifyToken} = useAuth();

    const handleTokenInput = (e) => {
        setToken(e.target.value)
    }

    const handleVerifyToken = () => {
        verifyToken(token, username)
            .then((res) => {
                localStorage.setItem("accessToken", res.accessToken)
                navigate("/dashboard");
            })
            .catch(() => {
                setShowError(true)
            })
    }

    const onKeyDown = (e) => {
        if(e.key === "Enter") handleVerifyToken()
    }

    return (
        <Box height={"100vh"}>
            <AppHeader/>
            <Container maxWidth={"xl"} sx={{p: 5, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", height:"calc(100% - 200px)"}}>
                <Box width={"50%"} display={"flex"} flexDirection={"column"} mt={5}>
                    <Typography variant={"h4"} textAlign={"center"}>Überprüfung des Bestätigungscodes</Typography>
                    <Typography variant={"p"} py={3} textAlign={"center"}>Um den Login abzuschließen, geben Sie den Bestätigungscode ein, den Sie per E-Mail erhalten haben. </Typography>
                    <Typography variant={"p"} py={3} textAlign={"center"}><strong>Danach die Enter-Taste oder Überprüfen klicken!</strong> </Typography>
                    <TextField value={token} onChange={handleTokenInput} sx={{bgcolor:"white", my:1}} fullWidth label="Bestätigungscode" variant="outlined" required  onKeyDown={onKeyDown} />
                    {showError && <Typography variant={"body1"} color={"error"} textAlign={"center"}>Falscher Bestätigungscode!
                        Bitte erneut versuchen.</Typography>}
                    <Button sx={{my:2, color:"white"}} variant={"contained"} color={"secondary"} onClick={handleVerifyToken}>Überprüfen</Button>
                </Box>
            </Container>
            <AppFooter/>
        </Box>
    )
}

export default VerifyToken;