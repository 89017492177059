import React, {useEffect} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import axios from "axios";

function DeleteDialogContract({open, handleClose, customerId, uuid}) {
    const [input, setInput] = React.useState("");
    const [disableDeleteBtn, setDisableDeleteBtn] = React.useState(true);

    const handleDelete = () => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
        };
        axios.delete(process.env.REACT_APP_BACKEND_API + `/contract/${uuid}/${customerId}`, config)
            .then(res => {
                setTimeout(() => {
                    window.location.reload();
                }, 2000)
            })
            .catch(e => {
                console.log(e)
            })
    }

    useEffect(() => {
        if(input === "LÖSCHEN"){
            setDisableDeleteBtn(false)
        }else {
            setDisableDeleteBtn(true)
        }
    },[input])

    return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Möchten Sie diesen Vertrag wirklich löschen?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Der Vertrag wird dadurch unwiderruflich gelöscht!
                        Bitte geben Sie deshalb zur Bestätigung in das untere Feld LÖSCHEN ein.
                    </DialogContentText>
                    <TextField
                        value={input}
                        onChange={(e) => {
                            setInput(e.target.value)
                        }}
                        autoFocus
                        margin="dense"
                        id="delete"
                        label="Löschen bestätigen"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <Button onClick={handleDelete} color={"error"} disabled={disableDeleteBtn}>
                        Löschen
                    </Button>
                </DialogActions>
            </Dialog>
    );
}

export default DeleteDialogContract;