export const companies = [
    {value:1, label: "Volksbank Brilon-Büren-Salzkotten eG"},
    // {value:2, label: "Volksbank Herford-Mindener Land eG"},
    // {value:3, label: "VerbundVolksbank OWL eG "},
]

export const salutations = [
    {value:1, label:"Herr"},
    {value:2, label:"Frau"},
    {value:3, label:"Divers"}
]