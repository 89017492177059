import React from "react";
import axios from "axios";

let NewContractContext = React.createContext();

export function NewContractProvider({ children }) {
    const [formValues, setFormValues] = React.useState({title:"VR-CyberGuard Vertrag", customer: "", template: "", notes: "", selectedCustomerId: null, customFormFields: []});
    const [fullCustomer, setFullCustomer] = React.useState({});
    const [templates, setTemplates] = React.useState([]);
    const [selectedTemplate, setSelectedTemplate] = React.useState({});
    const [nextBtnActive, setNextBtnActive] = React.useState(false);

    React.useEffect(() => {
        setFullCustomer(JSON.parse(sessionStorage.getItem("customer")))
    },[])

    React.useEffect(() => {
        setSelectedTemplate(templates.filter((template) => template.id === formValues.template)[0])
    },[formValues.template, templates])

    React.useEffect(() => {
        if(selectedTemplate && selectedTemplate.formFields){
            const customFormFieldCopy = selectedTemplate.formFields.map(formField => {
                return {
                    name: formField.name,
                    type: formField.type,
                    label: formField.label,
                    value: null
                }
            })
            setFormValues({
                ...formValues,
                customFormFields: customFormFieldCopy
            })
        }
    },[selectedTemplate])

    React.useEffect(() => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
        };
/*        axios.get(process.env.REACT_APP_BACKEND_API +"/customers", config)
            .then(r => {
                setCustomers(r.data)
            })*/
        axios.get(process.env.REACT_APP_BACKEND_API +"/contract/templates", config)
            .then(r => {
                setTemplates(r.data)
            })
    },[])


    const handleSetFormValues = (key, value) => {
        setFormValues({
            ...formValues,
            [key]: value,
        });
    };

    const handleSetValuesForCustomFormFields = (name, value) => {
        const customFormFieldCopy = [...formValues.customFormFields]
        customFormFieldCopy.forEach(customFormField => {
            if(customFormField.name === name) customFormField.value = value
        })
        setFormValues({
            ...formValues,
            customFormFields: customFormFieldCopy,
        });
    }

    let value = {
        nextBtnActive,
        setNextBtnActive,
        template: formValues.template,
        notes: formValues.notes,
        title: formValues.title,
        selectedCustomerId: formValues.selectedCustomerId,
        handleSetFormValues,
        templates,
        fullCustomer,
        formValues,
        selectedTemplate,
        handleSetValuesForCustomFormFields
    };

    return <NewContractContext.Provider value={value}>{children}</NewContractContext.Provider>;
}

export function useNewContract() {
    return React.useContext(NewContractContext);
}
