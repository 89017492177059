import React from "react";
import PageTitle from "../Components/PageTitle";
import {Box, Button, Grid, MenuItem, TextField, Typography} from "@mui/material";
import axios from "axios";
import CheckIcon from "@mui/icons-material/Check";
import {useAuth} from "../AuthContext";
import UserRow from "../Components/UserRow";

function UserManagement() {
    const { authenticatedUser } = useAuth()
    const [users, setUsers] = React.useState([]);
    const [newUser, setNewUser] = React.useState({username: "", password: "", email: "", organisation: null, role: "user"});
    const [organisations, setOrganisations] = React.useState([]);
    const [errors, setErrors] = React.useState([]);
    const [btnDisabled, setBtnDisabled] = React.useState(false);

    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
    };

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_API +'/users', config)
            .then(r => {
                if(r.status === 200) {
                    setUsers(r.data)
                }
            })
        if( "role" in authenticatedUser && authenticatedUser.role === "super-admin" ){
            axios.get(process.env.REACT_APP_BACKEND_API +'/organisations', config)
                .then(r => {
                    if(r.status === 200) {
                        setOrganisations(r.data)
                    }
                })
        }

    },[authenticatedUser])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser({
            ...newUser,
            [name]: value,
        });
    };

    const handleSaveNewUser = () => {
        setBtnDisabled(true)
        setErrors([])
        axios.post(process.env.REACT_APP_BACKEND_API +'/auth/signup', newUser, config)
            .then(r => {
                if(r.status === 201) {
                      setTimeout(() => {
                         window.location.reload();
                     }, 500)
                }
            }).catch((e) => {
            console.log(e)
            if(e.response.status === 400){
                setErrors(e.response.data.message)
            } else if(e.response.status === 409){
                setErrors([e.response.data.message])
            } else {
                setErrors(["Internal Server Error"])
            }
        }).finally(() => setBtnDisabled(false))

    }

    return (
        <>
            <PageTitle title={`Benutzer Verwaltung`} />
            <Box display={"flex"} flexWrap={"wrap"} p={1}>
                <Box sx={{width:{xs:"100%", lg: "75%"},pr:{md: 2}, borderRight: {xs: "none", lg: "solid"}, pb: 5, "& > div.user-row:nth-of-type(odd)":{bgcolor: "#0066b338"}}}>
                    <Typography variant={"h6"} pb={2}>Alle Benutzer</Typography>
                    <Grid container sx={{display:"flex", alignItems:"center",  borderBottom:"solid", mb:2,pb:1,pl:1}}>
                        <Grid item xs={2}>
                            Benutzername
                        </Grid>
                        <Grid item xs={3}>
                            E-Mail
                        </Grid>
                        {authenticatedUser.role === "super-admin" && <Grid item xs={4}>Bank</Grid>}
                        {authenticatedUser.role === "super-admin" && <Grid item xs={1}>Rolle</Grid>}
                    </Grid>
                    {
                        users.length > 0 && users.map(user =>
                            <UserRow user={user} key={user.username} organisations={organisations}/>
                        )
                    }
                </Box>
                <Box sx={{width:{xs:"100%", lg: "25%"},pl:{md: 2}, "& .MuiTextField-root":{my:2}, "& .MuiInputBase-root":{bgcolor: "white"}}}>
                    <Typography variant={"h6"} pb={2}>Neuen Nutzer anlegen</Typography>
                    {/*<TextField name="username" helperText="Wenn keiner Username angeben wird, wird ein 6-stelliger numerischer Username erzeugt. Zwischen 4 - 20 Zeichen" value={newUser.username} onChange={handleInputChange} fullWidth label="Username" variant="outlined"/>*/}
                    <TextField name="email" value={newUser.email} onChange={handleInputChange} fullWidth label="Email" variant="outlined" required/>
                    <TextField name="password" helperText="Mindestens 12 Zeichen" value={newUser.password} onChange={handleInputChange} fullWidth label="Password" variant="outlined" required/>
                    {authenticatedUser.role === "super-admin" &&
                        <TextField select={true} name="organisation" value={newUser.organisation} onChange={handleInputChange} fullWidth label="Bank" variant="outlined" required>
                        {organisations.length > 0 && organisations.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    }
                    {authenticatedUser.role === "super-admin" &&
                        <TextField select={true} name="role" value={newUser.role} onChange={handleInputChange} fullWidth label="Rolle" variant="outlined" required>
                            <MenuItem key={"user"} value={"user"}>Benutzer</MenuItem>
                            <MenuItem key={"admin"} value={"admin"}>Bank Admin</MenuItem>
                    </TextField>
                    }
                    {errors.length > 0 &&
                        <Box mb={2}>
                            <Typography variant={"body1"} color={"error"} textAlign={"left"}>Es gabe einen oder mehrere Fehler:</Typography>
                            {errors.map(error => <Typography variant={"body2"} color={"error"} textAlign={"left"}>- {error}</Typography>)}
                        </Box>
                    }
                    <Button variant={"contained"} endIcon={<CheckIcon/>} color={"secondary"} sx={{color: "white", float: "right"}}  disabled={btnDisabled} onClick={handleSaveNewUser}>Anlegen</Button>
                </Box>
            </Box>
        </>
    )
}

export default UserManagement;