import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import {Box, Button, Dialog, DialogContent, DialogTitle, IconButton, MenuItem, TextField, Typography} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import {useAuth} from "../AuthContext";

function EditUserDialog({user, open, setOpen, organisations}) {
    const { authenticatedUser } = useAuth()
    const [formValues, setFormValues] = React.useState(user);
    const [errors, setErrors] = React.useState([]);

    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handelSave = () => {
        axios.patch(process.env.REACT_APP_BACKEND_API +'/users/'+user.username, formValues, config)
            .then(r => {
                if(r.status === 200) {
                    setTimeout(() => {
                        setOpen(false)
                        window.location.reload();
                    }, 500)
                }
            }).catch((e) => {
            console.log(e)
            if(e.response.status === 400){
                setErrors(e.response.data.message)
            } else if(e.response.status === 409){
                setErrors([e.response.data.message])
            } else {
                setErrors(["Internal Server Error"])
            }
        })
    }

    const handelDelete = () => {
        axios.delete(process.env.REACT_APP_BACKEND_API + '/users/' + user.username, config).then(r => {
            setTimeout(() => {
            setOpen(false)
            window.location.reload();
        }, 500)})
    }

    const handleClose = () => setOpen(false);

    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth={"sm"}>
            <DialogTitle>
                { "Benutzer " + user.username + " bearbeiten"}
                <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{"& .MuiTextField-root": {my:2}}}>
                <TextField name="email" value={formValues.email} fullWidth label="Email ändern" variant="outlined" required onChange={handleInputChange}/>
                <TextField name="password" helperText="Mindestens 12 Zeichen" value={formValues.password} fullWidth label="Neues Password" variant="outlined" required onChange={handleInputChange}/>
                {authenticatedUser.role === "super-admin" && <TextField name="organisation" value={formValues.organisation} onChange={handleInputChange} fullWidth label="Bank ändern" select variant="outlined" required>
                    {organisations.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
                }
                {authenticatedUser.role === "super-admin" && <TextField name="role" value={formValues.role} onChange={handleInputChange} fullWidth label="Rolle" select variant="outlined" required>
                    <MenuItem key={"user"} value={"user"}>Benutzer</MenuItem>
                    <MenuItem key={"admin"} value={"admin"}>Bank Admin</MenuItem>
                </TextField>
                }
                {errors.length > 0 &&
                    <Box mb={2}>
                        <Typography variant={"body1"} color={"error"} textAlign={"left"}>Es gabe einen oder mehrere Fehler:</Typography>
                        {errors.map(error => <Typography variant={"body2"} color={"error"} textAlign={"left"}>- {error}</Typography>)}
                    </Box>

                }
                <Button variant={"contained"} endIcon={<CheckIcon/>} color={"secondary"} sx={{color: "white", float: "right"}} onClick={handelSave}>Speichern</Button>
                {authenticatedUser.role === "super-admin" && <Button variant={"contained"} endIcon={<DeleteIcon/>} color={"error"} sx={{color: "white", float: "left"}} onClick={handelDelete}>Löschen</Button>}
            </DialogContent>
        </Dialog>
    )
}

export default EditUserDialog;