import React from 'react';
import {
    Box,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import {useNewContract} from "../../NewContractFormProvider";

function ContractSelection() {
    const {handleSetFormValues, templates, template, setNextBtnActive} = useNewContract();

    const handleChange = (event) => {
        handleSetFormValues("template",event.target.value)
        setNextBtnActive(true)
    };

    React.useEffect(() => {
        if (template) setNextBtnActive(true)
    },[])

    return (
        <Box>
            <FormLabel>Wählen Sie aus den unten stehenden Verträgen einen aus.</FormLabel>
            <RadioGroup
                onChange={handleChange}
                value={template}
                sx={{mt:3, "& > label":{my:1}}}
            >
                {templates && templates.sort((a,b) => a.uiOrder - b.uiOrder).map((option) => (
                    <FormControlLabel
                        key={option.id}
                        value={option.id}
                        control={<Radio />}
                        label={<div>
                            {option.name} (
                                <a
                                    target="_blank"
                                    href={option.descriptionDocumentPath}
                                    rel="noreferrer">Leistungsverzeichnis
                                    <DownloadIcon color={"primary"} sx={{verticalAlign: "middle", ml:0.5}}/>
                                </a>
                                )
                            </div>}
                    />
                ))}
            </RadioGroup>
        </Box>
    )
}


export default ContractSelection;