import {Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, List, ListItem, ListItemText, Radio, RadioGroup, TextField, Typography} from "@mui/material";
import React from "react";

function CustomerContractFormFields({customFields, handleClose, open}) {
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Individuelle Formularfelder"}
            </DialogTitle>
            <DialogContent>
                <List>
                {
                    customFields.map(formField =>
                        <ListItem>
                            <ListItemText primary={formField.label} secondary={formField.value} />
                        </ListItem>
                    )
                }
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    handleClose();
                }}>Schließen</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CustomerContractFormFields