export const defaultValues = {
    companyName: "",
    companyEmail: "",
    companyTelephone: "",
    companyStreet: "",
    companyPlz: "",
    companyPlace: "",
    bankId: "",
    customerId: "",
    firstName: "",
    lastName: "",
    salutation: "",
    title: "",
    position: "",
    email: "",
    telephone: "",
    notes: "",
    employees: "",
    sector: "",
    organisationId: ""
}

export const getOptionLabelText = (option) =>{
    if(option === "") return ""
    if(parseFloat(option)) {
        const sector = sectors.find(sector => sector.id === option)
        return sector.id + ' ' + sector.title
    }
    return option.id + ' ' + option.title
}

export const sectors =
    [
        {
            "id": "1.01",
            "title": "Landwirtschaftsnahe Aktivitäten",
            "group": "Agrar"
        },
        {
            "id": "1.02",
            "title": "Pflanzliche Produktion",
            "group": "Agrar"
        },
        {
            "id": "1.03",
            "title": "Tierische Erzeugung",
            "group": "Agrar"
        },
        {
            "id": "1.04",
            "title": "Sonstiges",
            "group": "Agrar"
        },
        {
            "id": "2.01",
            "title": "Ausbaugewerbe",
            "group": "Bau"
        },
        {
            "id": "2.02",
            "title": "Grundstückserschließung und Bauträger",
            "group": "Bau"
        },
        {
            "id": "2.03",
            "title": "Hochbau",
            "group": "Bau"
        },
        {
            "id": "2.04",
            "title": "Tiefbau und Baustellenvorbereitung",
            "group": "Bau"
        },
        {
            "id": "2.05",
            "title": "Sonstiges",
            "group": "Bau"
        },
        {
            "id": "3.01",
            "title": "Gewinnung von Steinen und Erden",
            "group": "Bergbau"
        },
        {
            "id": "3.02",
            "title": "Sonstiges",
            "group": "Bergbau"
        },
        {
            "id": "4.01",
            "title": "Alten- und Pflegedienste",
            "group": "Dienstleistungen"
        },
        {
            "id": "4.02",
            "title": "Ärzte, Therapeuten und Zahntechniker",
            "group": "Dienstleistungen"
        },
        {
            "id": "4.03",
            "title": "Beherbergungsgewerbe",
            "group": "Dienstleistungen"
        },
        {
            "id": "4.04",
            "title": "Consulting",
            "group": "Dienstleistungen"
        },
        {
            "id": "4.05",
            "title": "Gastronomie",
            "group": "Dienstleistungen"
        },
        {
            "id": "4.06",
            "title": "Gebäudedienstleistungen",
            "group": "Dienstleistungen"
        },
        {
            "id": "4.07",
            "title": "Grundstücks- und Wohnungswesen",
            "group": "Dienstleistungen"
        },
        {
            "id": "4.08",
            "title": "Güterverkehr und Logistik",
            "group": "Dienstleistungen"
        },
        {
            "id": "4.09",
            "title": "IKT-Dienstleistungen",
            "group": "Dienstleistungen"
        },
        {
            "id": "4.10",
            "title": "Leasing und Leiharbeit",
            "group": "Dienstleistungen"
        },
        {
            "id": "4.11",
            "title": "Personenverkehr",
            "group": "Dienstleistungen"
        },
        {
            "id": "4.12",
            "title": "Persönliche Dienstleistungen",
            "group": "Dienstleistungen"
        },
        {
            "id": "4.13",
            "title": "Private Unterrichtsanbieter",
            "group": "Dienstleistungen"
        },
        {
            "id": "4.14",
            "title": "Reisen und Reservierungen",
            "group": "Dienstleistungen"
        },
        {
            "id": "4.15",
            "title": "Sport- und Freizeiteinrichtungen",
            "group": "Dienstleistungen"
        },
        {
            "id": "4.16",
            "title": "Technische Dienstleistungen",
            "group": "Dienstleistungen"
        },
        {
            "id": "4.17",
            "title": "Versicherungsdienstleistungen",
            "group": "Dienstleistungen"
        },
        {
            "id": "4.18",
            "title": "Werbung und Marktforschung",
            "group": "Dienstleistungen"
        },
        {
            "id": "4.19",
            "title": "Steuerberatung und Wirtschaftsprüfung",
            "group": "Dienstleistungen"
        },
        {
            "id": "4.20",
            "title": "Sonstiges",
            "group": "Dienstleistungen"
        },
        {
            "id": "5.01",
            "title": "Apotheken und Sanitätsfachhandel",
            "group": "Handel"
        },
        {
            "id": "5.02",
            "title": "E-Commerce",
            "group": "Handel"
        },
        {
            "id": "5.03",
            "title": "Einzelhandel Elektrotechnik",
            "group": "Handel"
        },
        {
            "id": "5.04",
            "title": "Einzelhandel Haus und Heim",
            "group": "Handel"
        },
        {
            "id": "5.05",
            "title": "Einzelhandel Mode und Drogerie",
            "group": "Handel"
        },
        {
            "id": "5.06",
            "title": "Einzelhandel Sport und Spiel",
            "group": "Handel"
        },
        {
            "id": "5.07",
            "title": "Einzelhandel Verlagsprodukte",
            "group": "Handel"
        },
        {
            "id": "5.08",
            "title": "Großhandel und Handelsvermittlung",
            "group": "Handel"
        },
        {
            "id": "5.09",
            "title": "Hörakustiker und Augenoptiker",
            "group": "Handel"
        },
        {
            "id": "5.10",
            "title": "Kfz-Handel, Werkstätten und Tankstellen",
            "group": "Handel"
        },
        {
            "id": "5.11",
            "title": "Lebensmitteleinzelhandel",
            "group": "Handel"
        },
        {
            "id": "5.12",
            "title": "Sonstiges",
            "group": "Handel"
        },
        {
            "id": "6.01",
            "title": "Automobilindustrie",
            "group": "Produktion"
        },
        {
            "id": "6.02",
            "title": "Baustoffindustrie",
            "group": "Produktion"
        },
        {
            "id": "6.03",
            "title": "Chemie- und Pharmaindustrie",
            "group": "Produktion"
        },
        {
            "id": "6.04",
            "title": "Elektrische Ausrüstungen",
            "group": "Produktion"
        },
        {
            "id": "6.05",
            "title": "Ernährungshandwerk",
            "group": "Produktion"
        },
        {
            "id": "6.06",
            "title": "Ernährungsindustrie",
            "group": "Produktion"
        },
        {
            "id": "6.07",
            "title": "Gummi- und Kunststoffindustrie",
            "group": "Produktion"
        },
        {
            "id": "6.08",
            "title": "Holz- und Möbelindustrie",
            "group": "Produktion"
        },
        {
            "id": "6.09",
            "title": "IT, Elektronik und Optik",
            "group": "Produktion"
        },
        {
            "id": "6.10",
            "title": "Maschinenbau",
            "group": "Produktion"
        },
        {
            "id": "6.11",
            "title": "Metallerzeugnisse",
            "group": "Produktion"
        },
        {
            "id": "6.12",
            "title": "Metallerzeugung und -bearbeitung",
            "group": "Produktion"
        },
        {
            "id": "6.13",
            "title": "Papier- und Druckindustrie",
            "group": "Produktion"
        },
        {
            "id": "6.14",
            "title": "Sonstiger Fahrzeugbau",
            "group": "Produktion"
        },
        {
            "id": "6.15",
            "title": "Textil- und Modeindustrie",
            "group": "Produktion"
        },
        {
            "id": "6.16",
            "title": "Sonstiges",
            "group": "Produktion"
        },
        {
            "id": "7.01",
            "title": "Entsorgungswirtschaft",
            "group": "Umwelt"
        },
        {
            "id": "7.02",
            "title": "Erneuerbare Energien",
            "group": "Umwelt"
        },
        {
            "id": "7.03",
            "title": "Sonstiges",
            "group": "Umwelt"
        }

]