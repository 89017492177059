import React from 'react';
import {Grid, Link, Tooltip, Typography} from "@mui/material";
import ContractStatus from "./ContractStatus";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function ServiceProviderContractRow({contract, newStatus}) {
    const [contractStatusOpen, setContractStatusOpen] = React.useState(false);
    return (
        <Grid container sx={{p:3, display:"flex", alignItems:"center"}} className={"contract-row"}>
            <Grid item xs={3}>
                {contract.contracts_title}
            </Grid>
            <Grid item xs={3} sx={{"& >p":{my:"5px"}}}>
                <p> {contract.customers_company_name}</p>
                <p>{contract.customers_company_street}</p>
                <p>{contract.customers_company_plz + " " + contract.customers_company_place}</p>
            </Grid>
            <Grid item xs={3} sx={{"& >p":{my:"5px"}}}>
                <p>{contract.customers_salutation} {contract.customers_title && " "+contract.customers_title}  {" " + contract.customers_first_name + " " + contract.customers_last_name}
                    {contract.customers_position && " ("+contract.customers_position+")"}</p>
                <p>{contract.customers_telephone}</p>
                <p>{contract.customers_email }</p>
            </Grid>
            <Grid item xs={1} >
                <Tooltip title={notes(contract.contracts_notes)} placement="top" arrow={true}><Link underline={"hover"} sx={{color: "#0066b3", cursor: "pointer"}} py={2} target={"_blank"}>Notizen</Link></Tooltip>
            </Grid>
            <Grid item xs={2} >
                <Link underline={"hover"} sx={{color: "#0066b3",  cursor: "pointer"}} pb={2} target={"_blank"}  onClick={() => setContractStatusOpen(true)}>Status Übersicht</Link>
                {contract.contracts_service_status &&
                    <>
                        <Typography variant={"body1"} pt={1}>Letzer Status:</Typography>
                        <Typography variant={"body2"}  pb={1}><CheckCircleOutlineIcon sx={{verticalAlign: "bottom"}} fontSize={"small"} color={"success"}/> {contract.contracts_service_status.sort((a, b) => new Date(b.date) - new Date(a.date))[0].title}</Typography></>
                }
                <ContractStatus newStatus={newStatus} status={contract.contracts_service_status} open={contractStatusOpen} handleClose={() => setContractStatusOpen(false)} uuid={contract.contracts_uuid}/>
            </Grid>
        </Grid>
    )
}

export default ServiceProviderContractRow;

const notes = (notes) => {
    return (<h2>{notes}</h2>)
}