import React from "react";
import {Box, Button, Container, TextField, Typography} from "@mui/material";
import AppHeader from "../Components/AppHeader";
import AppFooter from "../Components/AppFooter";
import LoadingButton from '@mui/lab/LoadingButton';
import axios from "axios";

function PasswordForget() {
    const [userName, setUserName] = React.useState("");
    const [btnDisabled, setBtnDisabled] = React.useState(false);
    const [btnLoading, setBtnLoading] = React.useState(false);
    const [showResetPasswordNote, setShowResetPasswordNote] = React.useState(false);
    const [showError, setShowError] = React.useState(false);

    const handleChangeUserName = (e) => {
        setUserName(e.target.value)
    }

    const handleForgetPassword = () => {
        setBtnDisabled(true)
        setBtnLoading(true)
        setShowError(false)
        if(userName.length > 0){
            axios.post(process.env.REACT_APP_BACKEND_API + "/auth/forget-password", {username: userName })
                .then((res) => {
                    if(res.data === true){
                        setShowResetPasswordNote(true);
                        setBtnLoading(false)
                    } else {
                        setShowError(true)
                        setBtnDisabled(false)
                        setBtnLoading(false)
                    }
                })
                .catch((e) => {
                    console.log(e);
                    setShowError(true)
                    setBtnDisabled(false)
                    setBtnLoading(false)
                })
        }
    }

    const onKeyDown = (e) => {
        if(e.key === "Enter") handleForgetPassword()
    }

    return (
        <Box height={"100vh"}>
            <AppHeader/>
            <Container maxWidth={"xl"} sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", height:"calc(100% - 200px)"}}>
                <Box width={"50%"} display={"flex"} flexDirection={"column"} sx={{"& > *": {my:"8px !important"}}}>
                    <Typography variant={"h4"} textAlign={"center"}>Password vergessen</Typography>
                    <Typography variant={"body1"} textAlign={"center"}>Geben Sie Ihren Benutzername ein und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts auf die mit Ihrem Konto verknüpfte E-Mail-Adresse.</Typography>
                    <TextField value={userName} onChange={handleChangeUserName} sx={{bgcolor:"white"}} fullWidth label="Benutzername" variant="outlined" required onKeyDown={onKeyDown}/>
                    {showResetPasswordNote && <Typography sx={{color: "#3c8800"}} variant={"body1"} textAlign={"center"}>Eine E-Mail mit einem Link zum Passwort zurücksetzen wurde and die hinterlegte E-Mail-Adresse gesendet.</Typography>}
                    {showError && <Typography  variant={"body1"} color={"error"} textAlign={"center"}>Es ist ein Fehler aufgetreten, bitte probieren Sie es erneut. Falls Sie schon eine E-Mail angefordert haben, kann der Versand einige Minuten in Anspruch nehmen. </Typography>}
                    {/*<Button sx={{color:"white"}} variant={"contained"} color={"secondary"} disabled={userName.length === 0 || btnDisabled} onClick={handleForgetPassword}>Link zum Passwort zurücksetzen anfordern</Button>*/}
                    <LoadingButton sx={{color:"white"}} loading={btnLoading} loadingPosition="end" disabled={userName.length === 0 || btnDisabled} color={"secondary"} variant="contained" onClick={handleForgetPassword}>Link zum Passwort zurücksetzen anfordern</LoadingButton>
                </Box>
            </Container>
            <AppFooter/>
        </Box>
    )
}

export default PasswordForget