import React from "react";
import {Box, Button, CircularProgress, Divider, Link, Tooltip, Typography} from "@mui/material";
import axios from "axios";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CustomerContractFormFields from "./CustomerContractFormFields";
import DeleteDialogContract from "./DeleteDialogContract";
import ContractStatus from "./ContractStatus";

function CustomerContractCard({contract, customerId, newStatus}) {
    const [selectedFile, setSelectedFile] = React.useState();
    const [isFilePicked, setIsFilePicked] = React.useState(false);
    const [fileError, setFileError] = React.useState("");
    const [uploadingFile, setUploadingFile] = React.useState(false);
    const [contractStatusOpen, setContractStatusOpen] = React.useState(false);
    const [customFormFieldsOpen, setCustomFormFieldsOpen] = React.useState(false);
    const [openDeleteContractDialog, setOpenDeleteContractDialog] = React.useState(false);

    const changeHandler = (event) => {
        setIsFilePicked(false)
        setSelectedFile(null);
        setFileError("");
        console.log(event.target.files)
        if(event.target.files[0].type !== "application/pdf"){
            setFileError("Bitte ein PDF auswählen")
        } else if (event.target.files[0].size > 10485760){
            setFileError("Datei zu groß. Maximal 10MB")
        } else {
            if(event.target.files[0]){
                setSelectedFile(event.target.files[0]);
                setIsFilePicked(true);
            }
        }
    };

    const downloadFile = (type) => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            responseType: 'blob', // important
        };
        axios
            .get(process.env.REACT_APP_BACKEND_API + `/contract/${type}/${contract.uuid}`, config)
            .then((response) => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], { type: "application/pdf" });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
            })
            .catch(() => {
                alert("Fehler beim laden der Datei ODER PopUp öffnen zulassen!")
            })
    }


    const handleUploadFile = () => {
        if(isFilePicked){
            setUploadingFile(true)
            const formData = new FormData();
            formData.append('file', selectedFile);

            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
            };
            axios.post(process.env.REACT_APP_BACKEND_API + `/contract/signed/${contract.uuid}/${customerId}`, formData, config)
                .then(res => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)
                })
                .catch(e => {
                    alert(e.message)
                    console.log(e)
                })
        }
    }

    const notes = (notes) => {
        return (<h2>{notes}</h2>)
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            border: "solid",
            flex: "1 1 0",
            m: 2,
            maxWidth: 350,
            minWidth: 300,
            textAlign: "center",
            borderRadius: 10,
            position: "relative",
            boxShadow: "5px 5px 27px 1px rgba(115,115,115,0.6)"
        }}>
            <Typography variant={"h6"} pb={2}>{contract.title}</Typography>
            <Typography variant={"body1"} pb={2} sx={{backgroundColor: contract.statusColor, borderRadius: 10, p:0, mb:2, color:"white" }}>{contract.statusText}</Typography>
            <Typography variant={"body1"} pb={2}>{new Date(contract.createdAt).toLocaleString("de-DE", {dateStyle: "short", timeStyle: "short"})} Uhr erstellt von {contract.editor}</Typography>
            {contract.statusCode === 1 &&
                <>
                    <Divider/>
                    {/*<Link href={getDraftDownloadLink()} underline={"hover"} sx={{color: "#0066b3"}} py={2} target={"_blank"}>Entwurf anzeigen</Link>*/}
                    <Link onClick={() => downloadFile("draft")} underline={"hover"} sx={{color: "#0066b3", cursor:"pointer"}} py={2} target={"_blank"}>Entwurf anzeigen</Link>
                </>}
            {(contract.notes && contract.notes.length > 1) &&
                <>
                    <Divider/>
                    <Tooltip title={notes(contract.notes)} placement="top" arrow={true}><Link underline={"hover"} sx={{color: "#0066b3", cursor: "pointer"}} py={2} target={"_blank"}>Notizen anzeigen</Link></Tooltip>
                </>
              }

            {
                contract.customFormFields &&
                <>
                    <Divider/>
                    <Link underline={"hover"} sx={{color: "#0066b3",  cursor: "pointer"}} py={2} target={"_blank"} onClick={() => setCustomFormFieldsOpen(true)}>Individuelle Formularfelder</Link>
                    <CustomerContractFormFields handleClose={() => setCustomFormFieldsOpen(false)} customFields={contract.customFormFields} open={customFormFieldsOpen} />
                </>
            }
            {(contract.statusCode === 1 || contract.statusCode === 4) &&
                <>
                    <Divider/>
                    <Link variant="text" component="label" underline={"hover"} sx={{cursor:"pointer"}} pt={2}>
                        Untersch. Vertrag hochladen
                        <input type="file" onChange={changeHandler} hidden accept="application/pdf"/>
                    </Link>
                </>
            }
            {isFilePicked &&
                <>
                    <Typography variant={"body1"}>{selectedFile.name}</Typography>
                    <Button variant={"contained"} onClick={handleUploadFile} disabled={uploadingFile}>
                        {uploadingFile && <CircularProgress sx={{alignSelf: "center", mr: 1, color: "white"}} size={20}/>}
                        Hochladen
                    </Button>
                </>
            }
            {fileError.length > 0 &&
                <Typography variant={"body1"} color={"error"}>{fileError}</Typography>
            }
            {(contract.statusCode === 1 || contract.statusCode === 4) && <Box pb={2}></Box>}

            {contract.statusCode === 2 &&
                <>
                    <Divider/>
                    <Box pt={2}/>
                    {contract.serviceStatus &&
                        <>
                            <Typography variant={"body1"}>Letzer Status:</Typography>
                            <Typography variant={"body2"}  pb={1}><CheckCircleOutlineIcon sx={{verticalAlign: "bottom"}} fontSize={"small"} color={"success"}/> {contract.serviceStatus.sort((a, b) => new Date(b.date) - new Date(a.date))[0].title}</Typography></>
                    }
                    <Link underline={"hover"} sx={{color: "#0066b3",  cursor: "pointer"}} pb={2} target={"_blank"} onClick={() => setContractStatusOpen(true)}>Status Übersicht anzeigen</Link>
                    <ContractStatus newStatus={newStatus} status={contract.serviceStatus} open={contractStatusOpen} handleClose={() => setContractStatusOpen(false)} uuid={contract.uuid}/>

                    <Divider/>
                    <Link onClick={() => downloadFile("signed")} target={"_blank"} underline={"hover"} sx={{color: "#0066b3", cursor:"pointer"}} pb={2} pt={2}>Vertrag anzeigen</Link>
                </>
}
            <Divider/>
            <Button sx={{p:2}} variant={"text"} color={"error"} onClick={()=>setOpenDeleteContractDialog(true)}>Löschen</Button>
            <DeleteDialogContract open={openDeleteContractDialog} handleClose={()=>setOpenDeleteContractDialog(false)} uuid={contract.uuid} customerId={customerId}/>
        </Box>
    )
}

export default CustomerContractCard;