import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import {Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ListItemAvatar, MenuItem, TextField, Typography} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";

function EditOrganisationDialog({organisation, open, setOpen}) {
    const [formValues, setFormValues] = React.useState(organisation);
    const [errors, setErrors] = React.useState([]);
    const handleClose = () => setOpen(false);

    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handelSave = () => {
        axios.patch(process.env.REACT_APP_BACKEND_API +'/organisations/'+organisation.id, formValues, config)
            .then(r => {
                if(r.status === 200) {
                    setTimeout(() => {
                        setOpen(false)
                        window.location.reload();
                    }, 500)
                }
            }).catch((e) => {
            console.log(e)
            if(e.response.status === 400){
                setErrors(e.response.data.message)
            } else if(e.response.status === 409){
                setErrors([e.response.data.message])
            } else {
                setErrors(["Internal Server Error"])
            }
        })
    }

    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth={"sm"}>
            <DialogTitle>
                { "Bank " + organisation.name + " bearbeiten"}
                <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{"& .MuiTextField-root": {my:2}}}>
                <TextField name="name" value={formValues.name} fullWidth label="Bank Name ändern" variant="outlined" required onChange={handleInputChange}/>
                {errors.length > 0 &&
                    <Box mb={2}>
                        <Typography variant={"body1"} color={"error"} textAlign={"left"}>Es gabe einen oder mehrere Fehler:</Typography>
                        {errors.map(error => <Typography variant={"body2"} color={"error"} textAlign={"left"}>- {error}</Typography>)}
                    </Box>

                }
                <Button variant={"contained"} endIcon={<CheckIcon/>} color={"secondary"} sx={{color: "white", float: "right"}} onClick={handelSave}>Speichern</Button>
            </DialogContent>
        </Dialog>
    )
}

export default EditOrganisationDialog;