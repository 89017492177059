import React from "react";
import {Box, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography} from "@mui/material";
import {useNewContract} from "../../NewContractFormProvider";

function Details() {
    const {handleSetFormValues, notes, title, fullCustomer, setNextBtnActive, selectedTemplate, handleSetValuesForCustomFormFields } = useNewContract();

    console.log(selectedTemplate)
    React.useEffect(() => {
        if (fullCustomer) setNextBtnActive(true)
    },[])

/*    const handleChangeCustomer = (event) => {
        handleSetFormValues("customer", event.target.value)
        setNextBtnActive(true)
    };*/

    const handleChangeNotes = (event) => {
        handleSetFormValues("notes", event.target.value)
    };

    const handleChangeTitle = (event) => {
        handleSetFormValues("title", event.target.value)
    };

    const handleChangeCustomFormFields = (event) => {
        handleSetValuesForCustomFormFields(event.target.name, event.target.value)
    }

    return (
            <Box sx={{"& .MuiTextField-root":{my:2, bgcolor:"white"}}}>
                <FormLabel sx={{mb:3, display:"block"}}>Bitte geben Sie Details zu dem Vertrag an.</FormLabel>
                <TextField fullWidth label="Titel" variant="outlined" value={title} required onChange={handleChangeTitle}/>
                <TextField fullWidth label="Kunde" variant="outlined"
                           value={`${fullCustomer.customerId}: ${fullCustomer.companyName} - ${fullCustomer.companyStreet}, ${fullCustomer.companyPlz} ${fullCustomer.companyPlace}`}
                           required disabled/>
{/*                <TextField fullWidth label="Kunde" select variant="outlined" value={customer} required onChange={handleChangeCustomer}>
                    {customers.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.customerId}: {option.companyName} - {option.companyStreet}, {option.companyPlz} {option.companyPlace}
                        </MenuItem>
                    ))}
                </TextField>*/}
                <TextField label="Weitere Hinweise" value={notes} multiline rows={5} fullWidth onChange={handleChangeNotes}/>
                {selectedTemplate.formFields &&
                    <>
                        <Divider sx={{m: 3}}><FormLabel>Weitere Felder (Abhängig von der Vertragsvorlage)</FormLabel></Divider>
                        <Box display={"flex"} flexDirection={"column"} gap={2}>
                        {
                            selectedTemplate.formFields.map(formField => {
                                switch (formField.type) {
                                    case "header": return <Typography key={formField.name} variant={"h6"}>{formField.label}</Typography>
                                    case "number": return <TextField key={formField.name} name={formField.name} id={formField.name} onChange={handleChangeCustomFormFields} type={"number"} label={formField.label} inputProps={{max: formField.max, min: formField.min, step: formField.step}}></TextField>
                                    case "text": return <TextField key={formField.name} name={formField.name} id={formField.name} onChange={handleChangeCustomFormFields} label={formField.label}></TextField>
                                    case "radio-group":
                                        return (
                                            <FormControl key={formField.name}>
                                                <FormLabel>{formField.label}</FormLabel>
                                                <RadioGroup name={formField.name} id={formField.name} onChange={handleChangeCustomFormFields}>
                                                    {formField.values.map(value => <FormControlLabel  name={formField.name} key={value.value} value={value.label} control={<Radio />} label={value.label} />)}
                                                </RadioGroup>
                                            </FormControl>
                                        )
                                    default:
                                        return "";
                                }
                            })
                        }
                        </Box>
                    </>
                }
            </Box>
    )
}

export default Details;