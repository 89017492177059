import React from "react";
import {Card, CardContent, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

function DashboardButtonCard({name, icon, path}) {
    let navigate = useNavigate();
    const handleClick = () => {
      navigate(path)
    }
    return (
        <Card
            onClick={handleClick}
            sx={{
            width: 300,
            height: 250,
            m:5,
            "& svg":{fontSize:150, color:"primary.main"},
            "&:hover":{cursor:"pointer", boxShadow:5}
        }}>
            <CardContent sx={{
                height:"100%",
                display:"flex",
                flexDirection:"column",
                alignItems:"center",
                justifyContent:"space-between"
            }}>
                {icon}
                <Typography variant={"h5"} color={"secondary"} textAlign={"center"}>{name}</Typography>
            </CardContent>
        </Card>
    )
}

export default DashboardButtonCard;