import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./Views/Login";
import Dashboard from "./Views/Dashboard";
import Customers from "./Views/Customers";
import Customer from "./Views/Customer";
import NewCustomer from "./Views/NewCustomer";
import NewContract from "./Views/NewContract";
import {ThemeProvider} from "@mui/material";
import theme from "./theme";
import {AuthProvider} from "./AuthContext";
import RequireAuth from "./RequireAuth";
import EditCustomer from "./Views/EditCustomer";
import {NewContractProvider} from "./NewContractFormProvider";
import VerifyToken from "./Views/VerifyToken";
import UserManagement from "./Views/UserManagement";
import OrganisationManagement from "./Views/OrganisationManagement";
import Settings from "./Views/Settings";
import FAQ from "./Views/FAQ";
import PasswordForget from "./Views/PasswordForget";
import PasswordReset from "./Views/PasswordReset";
import ServiceProviderContracts from "./Views/ServiceProviderContracts";
import Maintenance from "./Components/Maintenance";
const maintenance = false

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            {maintenance ? <Maintenance/>: <>
                <AuthProvider>
                    <BrowserRouter>
                        {/*https://reactrouter.com/docs/en/v6/getting-started/tutorial*/}
                        <Routes>
                            <Route path="login" element={<Login/>}/>
                            <Route path="forget-password" element={<PasswordForget/>}/>
                            <Route path="reset-password/:uuid" element={<PasswordReset/>}/>
                            <Route path="verify-token" element={<VerifyToken/>}/>
                            <Route path="/" element={<RequireAuth><App/></RequireAuth>}>
                                <Route path="dashboard" element={<Dashboard/>}/>
                                <Route path="user-management" element={<UserManagement/>}/>
                                <Route path="organisation-management" element={<OrganisationManagement/>}/>
                                <Route path="settings" element={<Settings/>}/>
                                <Route path="faq" element={<FAQ/>}/>
                                <Route path="service-provider/contracts" element={<ServiceProviderContracts/>}/>
                                <Route path="customer">
                                    <Route path="list-all" element={<Customers/>}/>
                                    <Route path=":id" element={<Customer/>}/>
                                    <Route path="new" element={<NewCustomer/>}/>
                                    <Route path="edit/:id" element={<EditCustomer/>}/>
                                    <Route path="new-contract" element={<NewContractProvider><NewContract/></NewContractProvider>}/>
                                </Route>
                            </Route>
                            <Route
                                path="*"
                                element={<h2>Keine Seite mit dieser URL gefunden</h2>}
                            />
                        </Routes>
                    </BrowserRouter>
                </AuthProvider>
            </>}
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
