import React from "react";
import PageTitle from "../Components/PageTitle";
import {Step, Stepper, StepLabel, Box, Button, CircularProgress} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContractSelection from "../Components/NewContract/ContractSelection";
import Details from "../Components/NewContract/Details";
import Summary from "../Components/NewContract/Summary";
import {useNewContract} from "../NewContractFormProvider";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const steps = ['Vertragsauswahl', 'Details', 'Speichern'];

function getStepContent(step) {
    switch (step) {
        case 0:
            return <ContractSelection />
        case 1:
            return <Details/>
        case 2:
            return <Summary/>
        default:
            return 'Unknown step';
    }
}

function NewContract() {
    let navigate = useNavigate();
    const {formValues, nextBtnActive, setNextBtnActive, fullCustomer} = useNewContract()
    const [activeStep, setActiveStep] = React.useState(0);


    const handleNext = () => {
        setNextBtnActive(false)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setNextBtnActive(false)
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCreateContract = () => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
        };
        const data = {
            title: formValues.title,
            customerId: fullCustomer.id,
            type: formValues.template,
            customFormFields: formValues.customFormFields,
            notes: formValues.notes,
        }
        axios.post(process.env.REACT_APP_BACKEND_API +"/contract/generate", data, config).then(r => {
            console.log(r);
            navigate(`/customer/${fullCustomer.id}`);
        })
    }

    return (
        <>
            <PageTitle title={"Neuen Vertrag erstellen"} />
            <Stepper alternativeLabel={true} activeStep={activeStep} sx={{my:10}}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {getStepContent(activeStep)}
           <Box sx={{display: "flex", justifyContent:"end", py:2, "& > button":{mx:1, my:5}}}>
               {activeStep > 0 && <Button variant={"contained"} startIcon={<ArrowBackIcon/>} color={"grey"}
                        onClick={handleBack}>Zurück</Button>}
               {activeStep < 2 && <Button
                   variant={"contained"}
                   endIcon={<ArrowForwardIcon/>}
                   color={"secondary"}
                   sx={{color: "white"}}
                   onClick={handleNext}
                   //Todo abfangen das kein Kunde ausgewählt ist:
                   disabled={!nextBtnActive}
               >Weiter</Button>}
               {activeStep === 2 && <Button disabled={!nextBtnActive} variant={"contained"} endIcon={nextBtnActive && <CheckIcon/>} color={"green"}
                        sx={{color: "white"}}
               onClick={handleCreateContract}>
                   {
                       !nextBtnActive ?
                           <>
                               <CircularProgress sx={{alignSelf: "center", mr: 1, color: "white"}} size={20}/> Bitte überprüfen
                           </>
                         :
                           "Vertrag erstellen"
                   }

               </Button>}
           </Box>
        </>
    )
}

export default NewContract;