import React from 'react';
import {Button, Grid} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditUserDialog from "./EditUserDialog";
import {useAuth} from "../AuthContext";

const getRoleName = (role) => {
    switch (role) {
        case "user":
            return "Benutzer"
        case "admin":
            return "Bank Admin"
        case "super-admin":
            return "Super Admin"
        default:
            return "";

    }
}

function UserRow({user, organisations}) {
    const [editDialogOpen, setEditDialogOpen] = React.useState(false);
    const { authenticatedUser } = useAuth()
    return (
        <>
            <Grid className={"user-row"} container sx={{display:"flex", alignItems:"center", py:2, pl:1}}>
                <Grid item xs={2}>
                    {user.username}
                </Grid>
                <Grid item xs={authenticatedUser.role === "super-admin" ? 3 : 8}>
                    {user.email}
                </Grid>
                {authenticatedUser.role === "super-admin" && <Grid item xs={4} sx={{textAlign: !user.organisation ? "center": "none"}}>
                    {user.organisation && user.organisation.name}
                    {!user.organisation && "-"}
                </Grid>}
                {authenticatedUser.role === "super-admin" && <Grid item xs={1}>
                    {getRoleName(user.role)}
                </Grid>}
                <Grid item xs={2} textAlign={"center"}>
                    <Button variant={"contained"} endIcon={<EditIcon/>} color={"secondary"} sx={{color: "white"}} onClick={() => setEditDialogOpen(true)}>Bearbeiten</Button>
                </Grid>
            </Grid>
            <EditUserDialog organisations={organisations} user={user} open={editDialogOpen} setOpen={() => setEditDialogOpen(false)}/>
        </>
    )
}

export default UserRow;