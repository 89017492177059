import React from "react";
import PageTitle from "../Components/PageTitle";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox, Dialog, DialogActions, DialogContent,
    DialogTitle,
    FormControlLabel,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import {salutations} from "../SelectOptions";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {defaultValues, getOptionLabelText, sectors} from "../Components/CustomerHelper";
import {useAuth} from "../AuthContext";


function NewCustomer() {
    const { authenticatedUser } = useAuth()
    const [formValues, setFormValues] = React.useState(defaultValues);
    const [organisations, setOrganisations] = React.useState([]);
    const [dsgvoDialogOpen, setDsgvoDialogOpen] = React.useState(false);
    let navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    React.useEffect(() => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
        };
        if(authenticatedUser.role === "super-admin"){
            axios.get(process.env.REACT_APP_BACKEND_API +'/organisations', config)
                .then(r => {
                    if(r.status === 200) {
                        setOrganisations(r.data)
                    }
                })
        }
    },[])

    const handleSubmit = (event) => {
        event.preventDefault();
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
        };
        axios.post(process.env.REACT_APP_BACKEND_API +"/customers", formValues, config)
            .then(r => {
                if(r.status === 201){
                    navigate(`/customer/${r.data.id}`)
                }
            })
    };

    return (
        <form onSubmit={handleSubmit}>
            <PageTitle title={"Neuen Kunden anlegen"} />
            <Box display={"flex"} flexWrap={"wrap"} sx={{"& > div":{my:2, px:2},"& .MuiTextField-root":{my:2, bgcolor:"white"}}}>
                <Box sx={{width:{xs:"100%", md: "50%"}}}>
                    <Typography variant={"h6"}>Unternehmen</Typography>
                    <TextField disabled name="customerId" value={formValues.customerId} onChange={handleInputChange} fullWidth label="Kundennummer (Wird automatisch erzeugt)" variant="outlined"/>
                    <TextField name="companyName" value={formValues.companyName} onChange={handleInputChange} fullWidth label="Name" variant="outlined" required/>
                    <TextField name="companyStreet" value={formValues.companyStreet} onChange={handleInputChange} fullWidth label="Straße" variant="outlined" required />
                    <Box>
                        <TextField inputProps={{ maxLength: 5}} sx={{width:"50%"}} name="companyPlz" value={formValues.companyPlz} onChange={handleInputChange} fullWidth label="PLZ" variant="outlined" required />
                        <TextField sx={{width:"50%"}} name="companyPlace" value={formValues.companyPlace} onChange={handleInputChange} fullWidth label="Ort" variant="outlined" required />
                    </Box>
                    <TextField name="companyEmail" value={formValues.companyEmail} onChange={handleInputChange} fullWidth label="E-Mail" variant="outlined" required/>
                    <TextField name="companyTelephone" value={formValues.companyTelephone} onChange={handleInputChange} fullWidth label="Telefonnummer" variant="outlined" required />
                    <Box>
                        {/*<TextField sx={{width:"75%"}} name="sector" value={formValues.sector} onChange={handleInputChange} fullWidth label="Branche" variant="outlined" required/>*/}
                        <Autocomplete
                            id={"sectors"}
                            name={"sector"}
                            // inputValue={autocompleteInput}
                            // onInputChange={(event, newInputValue) => {
                            //     setAutocompleteInput(newInputValue);
                            // }}
                            value={formValues.sector}
                            onChange={(e, value) => handleInputChange({target:{name: "sector", value: value.id}})}
                            isOptionEqualToValue={(option, value) => option.id === value}
                            options={sectors}
                            groupBy={(option) => option.group}
                            getOptionLabel={(option) => getOptionLabelText(option)}
                            sx={{width: "75%", display: "inline-flex"}}
                            renderInput={(params) => <TextField required {...params} label="Branche"/>}
                        />
                        <TextField type={"number"} sx={{width:"25%"}} name="employees" value={formValues.employees} onChange={handleInputChange} fullWidth label="Mitarbeiteranzahl" variant="outlined" required />
                    </Box>
                    {/*<TextField name="bankId" value={formValues.bankId} onChange={handleInputChange} fullWidth label="Volksbank" select variant="outlined" required>
                        {companies.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>*/}
                </Box>
                <Box sx={{width:{xs:"100%", md: "50%"}}}>
                    <Typography variant={"h6"}>Ansprechpartner für Durchführung </Typography>
                    <TextField name="salutation" value={formValues.salutation} onChange={handleInputChange} fullWidth label="Anrede" select variant="outlined" required>
                        {salutations.map((option) => (
                            <MenuItem key={option.label} value={option.label}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField name="title" value={formValues.title} onChange={handleInputChange} fullWidth label="Title" variant="outlined" />
                    <TextField name="firstName" value={formValues.firstName} onChange={handleInputChange} fullWidth label="Vorname" variant="outlined" required/>
                    <TextField name="lastName" value={formValues.lastName} onChange={handleInputChange} fullWidth label="Nachname" variant="outlined" required/>
                    <TextField name="position" value={formValues.position} onChange={handleInputChange} fullWidth label="Position" variant="outlined" />
                    <TextField name="email" value={formValues.email} onChange={handleInputChange} fullWidth label="E-Mail" variant="outlined" required/>
                    <TextField name="telephone" value={formValues.telephone} onChange={handleInputChange} fullWidth label="Telefonnummer" variant="outlined" required />
                </Box>
                <Box width="100%">
                    <TextField name="notes" value={formValues.notes} onChange={handleInputChange}  label="Besonderheiten im Unternehmen" multiline rows={5} fullWidth/>
                    <>
                        <FormControlLabel control={<Checkbox required />} label="Hiermit stimme ich der Datenspeicherung (DSGVO) zu*" />
                        <Typography sx={{display: "inline-block", verticalAlign: "text-top",  textDecoration: "underline", cursor: "pointer"}} onClick={() => setDsgvoDialogOpen(true)}>Informationen zur Datenspeicherung</Typography>
                    </>
                    {authenticatedUser.role === "super-admin" &&
                        <div>
                        <hr/>
                        <Typography variant={"body1"}>Diese Bereich ist nur für Admins sichtbar</Typography>
                        <TextField name="organisationId" value={formValues.organisationId} onChange={handleInputChange} fullWidth label="Bank" select variant="outlined" required>
                            {organisations.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>}
                </Box>
                <Box width="100%" sx={{p:"0 !important", display:"flex", justifyContent:"flex-end","& > button":{mx:2}}}>
                    <Button variant={"contained"} endIcon={<CloseIcon/>} color={"grey"}>Abbrechen</Button>
                    <Button variant={"contained"} endIcon={<CheckIcon/>} color={"secondary"} sx={{color: "white"}} type="submit">Speichern</Button>
                </Box>
            </Box>
            <DSGVODialog open={dsgvoDialogOpen} handleClose={() => setDsgvoDialogOpen(false)}/>
        </form>

    )
}

export default NewCustomer;


function DSGVODialog({open, handleClose}) {
    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth={"md"}>
            <DialogTitle>Informationen zur Datenspeicherung</DialogTitle>
            <DialogContent p={2}>
                <Typography fontWeight={"bold"}>Datenerhebung</Typography>
                <Typography>(1) Der Kunde willigt ein, dass die OWL Innovation GmbH die Daten, die sich aus diesem anbahnenden Vertrag oder der Vertragsdurchführung ergeben, erhebt, verarbeitet und nutzt und diese im erforderlichen Umfang an den jeweiligen Anbieter übermittelt:</Typography>
                <Typography>Art der Kommunikation</Typography>
                <ul>
                    <li>Telefonnummer / E-Mail - Kontakt - Name:</li>
                    <li>Adresse:</li>
                    <li>Festnetztelefon privat / dienstlich:</li>
                    <li>Mobiltelefon privat / dienstlich:</li>
                    <li>E-Mail privat /dienstlich</li>
                    <li>Individuelle / branchenspezifische Angaben des Unternehmens</li>
                </ul>
                <Typography>(2) Sollten sich auf Kundenseite Änderungen ergeben, so ist der Kunde mitverpflichtet diese Informationen bereitzustellen.</Typography>
                <Typography>(3) Die OWL Innovation GmbH ist dazu berechtigt Kundenkontakt zum Kunden aufzunehmen, sofern dies für die Beauftragung der Modulleistungen notwendig ist.</Typography>
                <br/>
                <Typography fontWeight={"bold"}>Datenschutzeinwilligung</Typography>
                <Typography>Der Kunde erteilt der OWL Innovation GmbH seine jederzeit widerrufliche Einwilligung zur elektronischen Erfassung und Speicherung seiner Daten. Die OWL Innovation verpflichtet sich, diese Daten nur im Rahmen der Vertragserfüllung und Vertragsdurchführung zu nutzen und sie entsprechend der datenschutzrechtlichen Bestimmungen zu behandeln. Die Inhalte zur Einwilligung der Datenspeicherung beziehen sich auf die oben genannten "Datenerhebung".</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>handleClose()}>Schließen</Button>
            </DialogActions>
        </Dialog>
    )
}
