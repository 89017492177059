import React from 'react';
import {Button, Grid} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditOrganisationDialog from "./EditOrganisationDialog";

function OrganisationRow({organisation}) {
    const [editDialogOpen, setEditDialogOpen] = React.useState(false);
    return (
        <>
            <Grid className={"organisation-row"} container sx={{display:"flex", alignItems:"center", py:2, pl:1}}>
                <Grid item xs={1}>
                    {organisation.id}
                </Grid>
                <Grid item xs={8}>
                    {organisation.name}
                </Grid>
                <Grid item xs={2} textAlign={"center"}>
                    <Button variant={"contained"} endIcon={<EditIcon/>} color={"secondary"} sx={{color: "white"}} onClick={() => setEditDialogOpen(true)}>Bearbeiten</Button>
                </Grid>
            </Grid>
            <EditOrganisationDialog organisation={organisation} open={editDialogOpen} setOpen={() => setEditDialogOpen(false)}/>
        </>
    )
}

export default OrganisationRow;