import {createTheme} from "@mui/material/styles";

let theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#1a4374',
        },
        secondary: {
            main: '#e05600',
        },
        green: {
            main: '#3c8800'
        },
        error: {
            main: '#b20000'
        }
    },
});


export default theme;