import React, {useState} from "react";
import PageTitle from "../Components/PageTitle";
import CustomersRow from "../Components/CustomersRow";
import {Box} from "@mui/material";
import CustomersRowHeader from "../Components/CustomersRowHeader";
import axios from "axios";

function Customers() {
    const [textFilter, setTextFilter] = useState("");
    const [customers, setCustomers] = useState([]);
    const [isLoading, setIsLoading] = useState(true)

    React.useEffect(() => {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
        };
        axios.get(process.env.REACT_APP_BACKEND_API +"/customers", config)
            .then(r => {
                setCustomers(r.data)
                setIsLoading(false)
            })
    }, []);


    const filteredCustomer = customers.filter((customer) => {
        if (textFilter.length === 0) return true
        return (customer.companyName.toLowerCase().includes(textFilter) || customer.customerId.toString().includes(textFilter))
    })

    return (
        <>
            <PageTitle title={"Alle Kunden - Kundenverwaltung"} />
            <Box sx={{"& > div.customer-row:nth-of-type(odd)":{bgcolor: "#0066b338"}}}>
                {isLoading ? <h3> Lädt Daten...</h3> : (
                    <>
                        <CustomersRowHeader setTextFilter={setTextFilter}/>
                        {filteredCustomer.length > 0 ?
                            filteredCustomer.map((customer) => <CustomersRow key={customer.id} company={customer}/> ):
                            <div>{textFilter.length > 0 ? "Kein Kunde mit Suchbegriff gefunden!" : "Keine Kunden vorhanden"}</div>
                        }
                    </>
)}
            </Box>

        </>

    )
}

export default Customers;