import React from "react";
import PageTitle from "../Components/PageTitle";
import {Box} from "@mui/material";
import DashboardButtonCard from "../Components/DashboardButtonCard";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SettingsIcon from '@mui/icons-material/Settings';
import {useAuth} from "../AuthContext";
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';


function Dashboard() {
    const { authenticatedUser } = useAuth()
    return (
        <Box>
            <PageTitle title={"Dashboard - Übersicht der Funktionen"} />
            <Box display={"flex"} justifyContent={"center"} flexWrap={"wrap"}>
                {authenticatedUser.role !== "service-provider" && <DashboardButtonCard name={"Kundenverwaltung"} icon={<GroupIcon/>} path="/customer/list-all"/>}
                {(authenticatedUser.role === "service-provider" || authenticatedUser.role === "super-admin") && <DashboardButtonCard name={"Vertragsübersicht für Dienstleister"} icon={<TextSnippetIcon/>} path="/service-provider/contracts"/>}
                {authenticatedUser.role !== "service-provider" && <DashboardButtonCard name={"Kunde hinzufügen"} icon={<GroupAddIcon/>} path="/customer/new"/>}
                {/*<DashboardButtonCard name={"Vertrag erstellen"} icon={<AddModeratorIcon/>} path="/customer/new-contract"/>*/}
                {(authenticatedUser.role === "admin" || authenticatedUser.role === "super-admin") && <DashboardButtonCard name={"Benutzer verwalten"} icon={<SupervisedUserCircleIcon/>} path="/user-management"/>}
                {(authenticatedUser.role === "super-admin") && <DashboardButtonCard name={"Banken verwalten"} icon={<AccountBalanceIcon/>} path="/organisation-management"/>}
                {(authenticatedUser.role === "super-admin") && <DashboardButtonCard name={"Einstellungen verwalten"} icon={<SettingsIcon/>} path="/settings"/>}
                <DashboardButtonCard name={"FAQ"} icon={<LiveHelpIcon/>} path="/faq"/>
            </Box>
        </Box>
    )
}

export default Dashboard;