import React from 'react';
import {Box, Button, Grid, TextField} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useNavigate} from "react-router-dom";

function CustomersRowHeader({setTextFilter}) {
    const [searchTerm, setSearchTerm] = React.useState("");
    let navigate = useNavigate();
    const handleClick = () => {
        navigate("/customer/new")
    }
    const handleChange = (e) => {
        setSearchTerm(e.target.value)
        setTextFilter(e.target.value.toLowerCase())
    }
    return (
        <Box>
            <Box sx={{px:2, display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                <TextField value={searchTerm} onChange={handleChange} id="outlined-basic" label="Suchen..." size="small" variant="outlined" sx={{width:"50%", "& > div":{bgcolor: "white"}, "& .MuiFormHelperText-root  p":{m:0}}} autoComplete="off" helperText={"Nach Kundennummer oder Name suchen. Sucht automatisch bei Eingabe!"}/>
                <Button variant={"contained"} endIcon={<AddIcon/>} color={"secondary"} sx={{color: "white"}} onClick={handleClick}>Neuer Kunde</Button>
            </Box>
            <Grid container sx={{display:"flex", alignItems:"center", px:3, pt:3, pb:1, mb:2, borderBottom:"solid"}}>
                <Grid item xs={2}>
                    Kundennummer
                </Grid>
                <Grid item xs={5}>
                    Name
                </Grid>
            </Grid>
        </Box>

    )
}

export default CustomersRowHeader;