import React from "react";
import {useAuth} from "./AuthContext";
import {useNavigate} from "react-router-dom";

function RequireAuth({children}) {
    const {checkAuth} = useAuth();
    let navigate = useNavigate();

    React.useEffect(() => {
        checkAuth()
            .then(() =>{})
            .catch(() => {
                navigate("/login");
            })
    }, []);

    return children;
}

export default RequireAuth;