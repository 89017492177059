import React from "react";
import {Box, IconButton, Typography} from "@mui/material";
import {useNavigate} from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import {useAuth} from "../AuthContext";

function AppHeader() {
    const [loggedIn, setLoggedIn] = React.useState(false);
    const {signout, checkAuth} = useAuth()
    let navigate = useNavigate();

    const handleLogout = () => {
        signout(() => navigate("/login"))
    }

    React.useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            setLoggedIn(true)
        }else {
            setLoggedIn(false)
        }
    },[])

    const handleGoToDashboard = () => {
        checkAuth().then(() => navigate("/dashboard")).catch(()=>{})
    }

    return(
        <Box sx={{height:100, boxShadow:3, p:2, bgcolor:"white", display:"flex", alignItems:"center", justifyContent:"space-between", position: "sticky", top: 0, zIndex: 10}}>
            <Typography onClick={handleGoToDashboard}  variant="h4" color="primary"
            sx={{cursor: "pointer", fontSize:{xs:"0.8rem", sm:"1rem", md:"1.5rem"}}}>VR-CyberGuard Vertragsportal</Typography>
            <Box sx={{height:"75%"}} display={"flex"} alignItems={"center"} >
                <Box sx={{height: {xs: "50%", md: "100%"}}} component={"img"} src={"/Logo_Innovation_GmbH.jpg"} alt={"vb-logo"}/>
                {loggedIn  && <IconButton aria-label="logout" size={"large"} sx={{ml: 5}} onClick={handleLogout}>
                    <LogoutIcon/>
                </IconButton>}
            </Box>
        </Box>
    )
}

export default AppHeader;