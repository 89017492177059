import React, {useEffect} from "react";
import { Outlet, useLocation, useNavigate} from "react-router-dom";
import AppHeader from "./Components/AppHeader";
import {Container, Box, Typography} from "@mui/material";
import AppFooter from "./Components/AppFooter";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function App() {
    let navigate = useNavigate();
    const {pathname} = useLocation();

    useEffect(() => {
        if (pathname === "/") navigate("/dashboard");
    })

    return (
        <Box>
            <AppHeader/>
            <Container maxWidth={"xl"} sx={{p: 5}}>
                {pathname !== "/dashboard" &&
                    <Typography onClick={() => navigate("/dashboard")} variant={"body1"} sx={{mb:1,cursor:"pointer", "&:hover": {color: "secondary.main"}}}>
                        <KeyboardBackspaceIcon sx={{verticalAlign: "top"}}/> zurück zum Dashboard
                    </Typography>
                }
                <Outlet/>
            </Container>
            <AppFooter/>
        </Box>
    );
}

export default App;
