import React from 'react';
import {Box, Button, Grid} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {useNavigate} from "react-router-dom";

function CustomersRow({company}) {
    let navigate = useNavigate();
    const handleClick = () => {
        navigate(`/customer/${company.id}`)
    }
    return (
        <Grid container sx={{p:3, display:"flex", alignItems:"center"}} className={"customer-row"}>
            <Grid item xs={2}>
                {addZeroToNumbers(company.id)}
            </Grid>
            <Grid item xs={8} >
                {company.companyName}
                <Box sx={{color:"#7e7e7e"}}>{company.companyStreet}, {company.companyPlz} {company.companyPlace}</Box>
            </Grid>
            {/*<Grid item xs={1} textAlign={"center"}>*/}
            {/*    {company.all ? company.all : 1}*/}
            {/*</Grid>*/}
            {/*<Grid item xs={1} textAlign={"center"}>*/}
            {/*    {company.open ? company.open : 1}*/}
            {/*</Grid>*/}
            {/*<Grid item xs={1} textAlign={"center"}>*/}
            {/*    {company.closed ? company.closed : 0}*/}
            {/*</Grid>*/}
            <Grid item xs={2} textAlign={"center"}>
                <Button variant={"contained"} endIcon={<ArrowForwardIcon/>} color={"secondary"} sx={{color: "white"}} onClick={handleClick}>Zum Kunden</Button>
            </Grid>
        </Grid>
    )
}

export default CustomersRow;

export function addZeroToNumbers(number) {
    let numString = number.toString()
    while (numString.length < 4) {
        numString = "0" +numString
    }
    return numString
}