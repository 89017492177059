import React from "react";
import {Box, Button, Container, Link, TextField, Typography} from "@mui/material";
import {useAuth} from "../AuthContext";
import {useNavigate} from "react-router-dom";
import AppHeader from "../Components/AppHeader";
import AppFooter from "../Components/AppFooter";

function Login() {
    const [userName, setUserName] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [btnDisabled, setBtnDisabled] = React.useState(false);

    const {login} = useAuth();
    let navigate = useNavigate();

    const handleSignIn = () => {
        setBtnDisabled(true)
        setErrorMessage("")
        login(userName, password)
            .then(() => navigate("/verify-token?user=" + userName ))
            .catch((err) => {
                if(err.response.data.message){
                    setErrorMessage(err.response.data.message)
                }else {
                    setErrorMessage("Es gab einen Server Fehler")
                }
                setShowError(true)
            })
            .finally(() => setBtnDisabled(false))
    }

    const handleChangeUserName = (e) => {
        setUserName(e.target.value)
        setShowError(false)
    }

    const handleChangePassword = (e) => {
        setPassword(e.target.value)
        setShowError(false)
    }

    return (
        <Box height={"100vh"}>
            <AppHeader/>
            <Container maxWidth={"xl"} sx={{p: 5, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", height:"calc(100% - 200px)"}}>
                <Box>
                    <Typography variant={"h3"} textAlign={"center"} mb={4}>Willkommen im VR-CyberGuard Vertragsportal</Typography>
{/*                    <Typography variant={"h6"}>In diesem Vertragsportal haben Sie u.a. folgende Möglichkeiten</Typography>
                    <ul>
                        <li><Typography variant={"body1"}>Kunden einpflegen und verwalten</Typography></li>
                        <li><Typography variant={"body1"}>VR CyberGuard Verträge erstellen</Typography></li>
                        <li><Typography variant={"body1"}>Verträge verwalten</Typography></li>
                        <li><Typography variant={"body1"}>Unterschriebene Verträge hochladen</Typography></li>
                    </ul>*/}
                </Box>
                <Box width={"50%"} display={"flex"} flexDirection={"column"} mt={5}>
                    <Typography variant={"h4"} textAlign={"center"} pb={1}>Anmeldung</Typography>
                    <TextField value={userName} onChange={handleChangeUserName} sx={{bgcolor:"white", my:1}} fullWidth label="Benutzername" variant="outlined" required />
                    <TextField value={password} onChange={handleChangePassword} sx={{bgcolor:"white", my:1}} fullWidth label="Password" variant="outlined" required type={"password"} autoComplete={"false"}/>
                    <Link variant={"subtitle2"} textAlign={"center"} underline={"hover"} sx={{cursor: "pointer", pt:1}} onClick={() => {navigate("/forget-password")}}>Passwort vergessen?</Link>
                    {showError && <Typography variant={"body1"} color={"error"} textAlign={"center"}>{errorMessage}</Typography>}
                    <Button sx={{my: 2, color: "white"}} variant={"contained"} color={"secondary"} onClick={handleSignIn} disabled={btnDisabled}>Anmelden {btnDisabled && " ..."}</Button>
                </Box>
            </Container>
            <AppFooter/>
        </Box>
    )
}

export default Login