import React from 'react';
import PageTitle from "../Components/PageTitle";
import {Box, Button, CardMedia, Divider, Typography} from "@mui/material";
import QuizIcon from '@mui/icons-material/Quiz';
import DownloadIcon from "@mui/icons-material/Download";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

function FAQ() {
    return(
        <>
            <PageTitle title={"FAQ & weiteres Info Material"} />
            <Typography variant={"h6"}>FAQ - PDF</Typography>
            <Button variant={"contained"} color={"secondary"} sx={{color: "white", my: 2}} endIcon={<QuizIcon/>} href={"docs/faq.pdf"} target={"_blank"}>FAQ PDF ansehen</Button>
            <Divider sx={{my:3}}/>
            <Typography variant={"h6"}>Informationen zur Datenspeicherung (Datenschutzeinwilligung)</Typography>
            <Button variant={"contained"} color={"secondary"} sx={{color: "white", my: 2}} endIcon={<AdminPanelSettingsIcon/>} href={"docs/Informationen-zur-Datenspeicherung.pdf"} target={"_blank"}>Informationen zur Datenspeicherung ansehen</Button>
            <Divider sx={{my:3}}/>
            <Typography variant={"h6"}>VR-CyberGuard Erklärvideo</Typography>
            <CardMedia component={"video"} src="https://portal.vrcyberguard.de/VR-CyberGuard.mp4" controls sx={{width: {xs: "100%", md: "75%"}}}/>
            <Divider sx={{my:3}}/>
            <Typography variant={"h6"}>Leistungsverzeichnisse der Verträge</Typography>
            <Box>
                {contracts.map((contract) => (
                    <Button sx={{m: 2}} color={"secondary"} variant={"contained"} href={contract.docPath} target="_blank" endIcon={<DownloadIcon />}>
                        {contract.label}
                </Button>))}
            </Box>
        </>

    )
}

const contracts = [
    {id: 1, label: "VR-CyberGuard Basic", docPath: "/docs/Leistungsverzeichnis-Basic.pdf"},
    {id: 1, label: "VR-CyberGuard Premium", docPath: "/docs/Leistungsverzeichnis-Premium.pdf"},
    {id: 1, label: "VR-CyberGuard Individuell", docPath: "/docs/Leistungsverzeichnis-Individuell.pdf"}
]

export default FAQ;